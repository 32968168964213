import IconWrapper from '../wrapper/IconWrapper';

const InstagramIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2766.03" height="2765.97" viewBox="0 0 2766.03 2765.97">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <path d="M1913.83,0H852.26C381.53,0,0,381.56,0,852.33v1061.5C0,2384.45,381.53,2766,852.26,2766H1913.83c470.71,0,852.2-381.52,852.2-852.14V852.33C2766,381.56,2384.54,0,1913.83,0Zm586.72,1811.92c0,380.43-308.41,688.63-688.57,688.63H954.12c-380.38,0-688.7-308.2-688.7-688.63V954.07c0-380.18,308.32-688.64,688.7-688.64H1812c380.16,0,688.57,308.46,688.57,688.64Z" fill="#44506c"/>
                        <path d="M1420.12,659.29c-402.48,0-728.66,326.08-728.66,728.53,0,402.24,326.18,728.55,728.66,728.55s728.54-326.31,728.54-728.55C2148.66,985.37,1822.46,659.29,1420.12,659.29Zm0,1166.84c-242.27,0-438.44-196.34-438.44-438.31,0-242.24,196.17-438.35,438.44-438.35,242,0,438.3,196.11,438.3,438.35C1858.42,1629.79,1662.13,1826.13,1420.12,1826.13Z" fill="#44506c"/>
                        <path d="M2283.52,647.91c0,102.33-83.11,185.25-185.33,185.25S1913,750.24,1913,647.91s82.88-185.37,185.19-185.37S2283.52,545.64,2283.52,647.91Z" fill="#44506c"/>
                    </g>
                    </g>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default InstagramIcon;