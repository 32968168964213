import Grid from '../grid/Grid';

const ProductList = ({ products = [], renderItem }) => {

    if (!products.length) { return null; }

    return (
        <Grid xsSpacing={3} smSpacing={4} container>
            {
                products.map((product, index) => {
                    return (
                        <Grid key={index} xs={6} sm={3}>
                            { renderItem(product, index) }
                        </Grid>
                    );
                })
            }
        </Grid>
    );
}

export default ProductList;