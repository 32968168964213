import { useEffect, useState } from 'react';

import Anytyme from '../lib/api/Anytyme';

const useProduct = (sku, prefilled_product=null) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [product, setProduct] = useState(prefilled_product);

    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);
 
            try {
                const { data=[] } = await Anytyme.getProduct(sku);

                const product = data.map(({ id, attributes }) => {

                    const categories = attributes.categories.data.map(({ id, attributes }) => {
                        return {
                            id: id,
                            ...attributes
                        }
                    });

                    let image = null;

                    if (attributes?.image?.data) {
                        image = {
                            id: attributes.image.data.id,
                            ...attributes.image.data.attributes
                        }
                    }

                    return {
                        id: id,
                        ...attributes,
                        categories: categories,
                        image: image
                    }
                });

                setProduct(product[0]);
            } catch (error) {
                setError(error);
            }
        
            setLoading(false);
        
    
        }

        fetchData();


    }, [ sku ]);

    return {
        product: product,
        error: error,
        loading: loading,
    };
}

export default useProduct;