import { useState } from 'react';

import Grid from '../../components/grid/Grid';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

import useMediaQuery from '../../hooks/useMediaQuery';

import BuilderContent from '../../builder/BuilderContent';

import {
    Input,
    Checkbox,
    Button,
    Form
} from '../../components/form';

import classes from './Newsletter.module.css';

const Newsletter = ({ nav }) => {
    const [ inputs, setInput ] = useState({ name: '', email: '', policy: false });
    const { match } = useMediaQuery('(min-width: 768px)');

    const spacing = (match ? 0 : 4);

    const onSubmit = () => {

    }

    const onChange = () => {

    }

    return (
        <>
            {
                match
                &&
                <Grid xsSpacing={5} smSpacing={4} container>
                    <Grid>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            sufix={[{ label: nav.name }]}
                        />
                    </Grid>
                </Grid>
            }
            
            <div className="container-small">
                <Grid container className={classes.root}>
        
                    <Card className={classes.card}>
                    <   Grid xsSpacing={0} smSpacing={4} container>
                            
                            <Grid spacing={0} md={12} className="builder_header">
                                <BuilderContent 
                                    model="nieuwsbrief" 
                                    url="/nieuwsbrief"
                                    renderLoading={() => <Loading className={classes.loading}/>}
                                />
                            </Grid>

                            <Grid sx={12} >
                                <Form onSubmit={onSubmit} className={classes.form}>
                                    <Grid container>

                                        <Grid sm={6} xs={12} className={classes.form_field}>
                                            <Input name="name" label="Voornaam" onChange={onChange} value={inputs.name} required />
                                        </Grid>

                                        <Grid sm={6} xs={12} className={classes.form_field}>
                                            <Input type="email" name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required/>
                                        </Grid>

                                        <Grid sm={6} xs={12} className={classes.form_field}>
                                            <Checkbox
                                                name="policy"
                                                required
                                                onChange={onChange}
                                                value={inputs.policy}
                                                label={<> Ik ga akkoord met de <a href="http://localhost:3000">privacy policy</a>.</>}
                                            />
                                        </Grid>

                                        <Grid sm={6} xs={12} className={classes.form_field + ' ' + classes.button}>
                                            <Button variant="primary" className={classes.send}>Verstuur</Button>
                                        </Grid>
                                        
                                    </Grid>
                                </Form>
                            </Grid>
                    
                        </Grid>
                    </Card>
                
                </Grid>
            </div>
        </>

    );

}

export default Newsletter;