import { StarEmptyIcon, StarFullIcon } from '../../icons';

import { useDispatch, useSelector } from 'react-redux';

import { addFavoriteLocation } from '../../../redux/application/actions';

import classes from './FavoriteButton.module.css';

const FavoriteButton = ({ location, size = 25 }) => {
    const dispatch = useDispatch();
    const { favorite_location } = useSelector(state => state.app);

    const onButtonClick = () => {
        if (favorite_location !== null && favorite_location.storeCode === location.storeCode) {
            dispatch(addFavoriteLocation(null));
        } else {
            dispatch(addFavoriteLocation(location));
        }
    }

    return (
        <div onClick={onButtonClick} className={classes.icon}>
            {
                (favorite_location !== null && favorite_location.storeCode === location.storeCode)
                    ?
                    <StarFullIcon size={size} />
                    :
                    <StarEmptyIcon size={size} />
            }
        </div>
    );
}

export default FavoriteButton;