import IconWrapper from '../wrapper/IconWrapper';

const FacebookIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.777" height="14.584" viewBox="0 0 6.777 14.584">
                <path d="M24.759,10.818H22.451V9.3a.616.616,0,0,1,.642-.7h1.629V6.1L22.479,6.1a2.84,2.84,0,0,0-3.057,3.057v1.666h-1.44v2.575h1.44V20.68h3.029V13.393h2.044Z" transform="translate(-17.982 -6.096)" fill="#44506c"/>
            </svg>
        </IconWrapper>
    );
}

export default FacebookIcon;