import { Builder, Image } from "@builder.io/react";

import CardComponent from '../../components/card/Card';
import Grid from '../../components/grid/Grid';
import classes from './BannerText.module.css';

export const BannerText = props => {
    const { Padding, sectionTitle, text, image } = props;

    return (
        <Grid spacing={4} container>
            <CardComponent className={classes.card} style={{ padding: Padding + 'px' }}>
                <Grid container>
                    <Grid sm={7}>
                        <div className={classes.imageContainer}>
                            <Image lazy aspectRatio={1} image={image} />
                        </div>
                    </Grid>
                    <Grid sm={5} className={classes.text_container}>
                        <div className={classes.title}>
                            {sectionTitle}
                        </div>
                        <div className={classes.text}>
                            {text}
                        </div>
                    </Grid>
                </Grid>
            </CardComponent>
        </Grid>
    );
}


Builder.registerComponent(BannerText, {
    name: "Banner Text",
    inputs: [
        {
            type: 'string',
            name: 'Padding',
            required: true,
            enum: [
                {
                    label: 'Padding 10',
                    value: '10',
                },
                {
                    label: 'Padding 20',
                    value: '20',
                },
                {
                    label: 'Padding 30',
                    value: '30',
                }
            ],
            defaultValue: '10',
        }, {
            name: 'sectionTitle',
            type: 'string',
            defaultValue: 'Your Title Here',
        },
        {
            name: 'text',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
    ],
});
