import { Builder } from "@builder.io/react";
import { Route } from 'react-router-dom'

import LocationSearchComponent from '../../components/locations/search/LocationSearch';
import Grid from '../../components/grid/Grid';

export const LocationSearch = () => (
    <Grid spacing={4} container>
        <Route render={({ history }) => (
            <LocationSearchComponent onSearch={(value) => history.push('/vestigingen?locatie=' + value)} />
        )} />
    </Grid>
);

Builder.registerComponent(LocationSearch, {
    name: "Location Search"
});