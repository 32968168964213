import { Fragment, useMemo } from 'react';

import classes from './Breadcrumb.module.css';

import { Link } from 'react-router-dom';

import { ChevronIcon } from '../icons';

const Breadcrumb = ({ paths=[], prefix=[], sufix=[] }) => {

    const all_paths = useMemo(() => {
        if (paths === null) { return []; }
        
        return [
            ...prefix,
            ...paths,
            ...sufix
        ]
    }, [paths, prefix, sufix]);

    return (
        <div className={classes.root}>
            {
                all_paths.map(({ label, path, prefix }, index) => {
                    return ( 
                        <Fragment key={"b"+index}>
                            { index !== 0 && <ChevronIcon size={8} className={classes.chevron} /> }
                            {
                                path
                                    ?
                                    <Link to={path} className={ classes.link }>{ label }</Link> 
                                    :
                                    <span className={ classes.link }>{ label }</span> 
                            }
                        </Fragment>
                    )
                })
            }
        </div>
    );
}

export default Breadcrumb;