import classNames from 'classnames';
import classes from './Image.module.css';

const Image = ({ responsive, lazy, alt = '', ratio, className=null, ...props }) => {

    const imageClassName = classNames(classes.root, {
        [classes.responsive]: responsive
    });

    const containerClassName = classNames(classes.container, classes[ratio], className);

    if (lazy) {
        return (
            <div className={containerClassName}>
                <img loading="lazy" className={imageClassName} alt={alt} {...props} />
            </div>
        );
    } else {
        return (
            <img className={imageClassName} alt={alt} {...props} />
        );
    }
}

export default Image;