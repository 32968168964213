import { useDispatch, useSelector } from 'react-redux';

import { setCache, deleteCache, clearCache } from '../redux/cache/actions';


const useCache = () => {
    
    const dispatch = useDispatch();
    const cache = useSelector(state => state.cache);

    const get = (key) => {
        return cache[key] || null;
    };

    const set = (key, value) => {
        dispatch(setCache({
            key,
            value
        }));
    }

    const remove = (key) => {
        dispatch(deleteCache(key));
    }

    const clear = () => {
        dispatch(clearCache());
    }

    /*const value = useMemo(() => {
        return cache[key] || null;
    }, [key, cache]);*/

    return {
        get,
        set,
        remove,
        clear
    };
}

export default useCache;