import classNames from 'classnames';

import classes from './Card.module.css';

const Card = ({ children, radius, className='', ...props }) => {

    const cardClassName = classNames(classes.root, classes['radius-' + radius], className);

    return (
        <div className={cardClassName} {...props}>
            { children }
        </div>
    );
}

export default Card;