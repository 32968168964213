import IconWrapper from '../wrapper/IconWrapper';

const MapIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.165" height="18.042" viewBox="0 0 15.165 18.042">
                <path d="M7.582,18.042a.919.919,0,0,1-.6-.224,19.52,19.52,0,0,1-4.989-4.672A9.65,9.65,0,0,1,0,7.582V7.557A7.583,7.583,0,0,1,7.581,0h.026a7.592,7.592,0,0,1,7.558,7.608v.086c-.032,1.668-.654,4.272-3.436,7.2a19.958,19.958,0,0,1-3.546,2.92A.923.923,0,0,1,7.582,18.042Zm0-12.77A2.43,2.43,0,1,0,10.012,7.7,2.433,2.433,0,0,0,7.583,5.272Z" />
            </svg>
        </IconWrapper>
    );
}

export default MapIcon;