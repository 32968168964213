import WebService from '../webservice/WebService';
import qs from 'qs';

let instance = null;

class Anytyme {

    constructor({ url, port }) {
     
        if(!instance){

            this.url = url;

            this.webservice = new WebService({
                url: url,
                port: port
            });

            instance = this;
        }
    }

    static getLocations = () => { return instance.getLocations(); }
    getLocations() {
        const query = qs.stringify({
            populate: [
                'image',
                'extra_image',
                'regularHours',
                'regularHours.monday',
                'regularHours.tuesday',
                'regularHours.wednesday',
                'regularHours.thursday',
                'regularHours.friday',
                'regularHours.saturday',
                'regularHours.sunday',
                'specialHours',
                'regularDeliveryHours',
                'regularDeliveryHours.monday',
                'regularDeliveryHours.tuesday',
                'regularDeliveryHours.wednesday',
                'regularDeliveryHours.thursday',
                'regularDeliveryHours.friday',
                'regularDeliveryHours.saturday',
                'regularDeliveryHours.sunday',
                'specialDeliveryHours'
            ],
            pagination: {
                page: 1,
                pageSize: 1000
            }
        },{
              encodeValuesOnly: true,
        });

        return this.webservice.get('/locations?'+query);
    }

    static getLocationsNear = (data) => { return instance.getLocationsNear(data); }
    getLocationsNear({ lon, lat, maxDistance }) {
        return this.webservice.get('/locations/near', {
            lon: lon,
            lat: lat,
            maxDistance: maxDistance
        });
    }

    static getLocation = (key) => { return instance.getLocation(key); }
    getLocation(key) {
        
        const query = qs.stringify({
            sort: [ 'name' ],
            'filters[key]': key,
            populate: [
                'image',
                'extra_image',
                'regularHours',
                'regularHours.monday',
                'regularHours.tuesday',
                'regularHours.wednesday',
                'regularHours.thursday',
                'regularHours.friday',
                'regularHours.saturday',
                'regularHours.sunday',
                'specialHours',
                'regularDeliveryHours',
                'regularDeliveryHours.monday',
                'regularDeliveryHours.tuesday',
                'regularDeliveryHours.wednesday',
                'regularDeliveryHours.thursday',
                'regularDeliveryHours.friday',
                'regularDeliveryHours.saturday',
                'regularDeliveryHours.sunday',
                'specialDeliveryHours'
            ]
        },{
            encodeValuesOnly: true,
        });

        return this.webservice.get('/locations?'+query);
    }

    static getPages = () => { return instance.getPages(); }
    getPages() {
        return this.webservice.get('/pages');
    }

    static getCategories = () => { return instance.getCategories(); }
    getCategories() {

        const query = qs.stringify({
            populate: [
                'image',
                'products',
                'products.image'
            ]
        },{
            encodeValuesOnly: true,
        });

        return this.webservice.get('/categories?'+query);
    }

    static getCategory = (key) => { return instance.getCategory(key); }
    getCategory(key) {
        return this.webservice.get('/categories', { key: key });
    }

    static getProduct = (sku) => { return instance.getProduct(sku); }
    getProduct(sku) {
        const query = qs.stringify({
            'filters[sku]': sku,
            populate: [
                'categories',
                'image'
            ]
        },{
              encodeValuesOnly: true
        });

        return this.webservice.get('/products?' + query);
    }

    static addContact = (params) => { return instance.addContact(params); }
    addContact(params) {
        return this.webservice.post('/email/contact', params);
    }

    static addFranchise = (params) => { return instance.addFranchise(params); }
    addFranchise(params) {
        return this.webservice.post('/email/franchise', params);
    }

    static getVacancies = () => { return instance.getVacancies(); }
    getVacancies() {

        const query = qs.stringify({
            populate: [
                'locations',
            ]
        },{
            encodeValuesOnly: true,
        });

        return this.webservice.get('/vacancies?'+query);
    }

    static applyVacancy = (params) => { return instance.applyVacancy(params); }
    applyVacancy(params) {

        return new Promise(async (resolve, reject) => {
            try {

                const formData = new FormData();
                for ( const key in params ) {
                    formData.append(key, params[key]);
                }

                const result = await fetch(`${this.url}/vacancy/apply`, {
                    method: "POST",
                    body: formData,
                });
          
                const data = await result.json();
          
                console.log(data);
                resolve(data);
              } catch (error) {
                console.error(error);
                resolve(error);
              }
        });

    }

}

export default Anytyme;