import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { CrossIcon, MenuIcon } from '../../icons';

import Portal from '../../portal/Portal';
import Drawer from '../../drawer/Drawer';
import SocialLinks from '../../social-links/SocialLinks';

import classes from './Menu.module.css';

import useNavigation from '../../../hooks/useNavigation';


const Menu = () => {
    const [open, setOpen] = useState(false);
    const { tree } = useNavigation();
    
    useEffect(() => {
        return () => {
            document.body.classList.remove(classes['menu_open']);
        }
    }, [])

    const toggle = () => {
        setOpen(!open);
        document.body.classList.remove(classes[(open ? 'menu_open' : 'menu_open')]);
    }

    return (
        <>
            
            <div className={classes.icon_wrapper} onClick={toggle}>
                {
                    open
                        ?
                        <CrossIcon color="#fff" size="20" />
                        :
                        <MenuIcon color="#fff" size="20" />
                }
            </div>

            <Portal>
                <Drawer open={open} openClass={classes.open}>
                    <div className={classes.modal}>
                        <div className={classes.background} onClick={toggle}></div>

                        <aside className={classes.details}>
                            <ul className={classes.menu_item_list}>
                                <li className={classes.menu_item}><NavLink to={'/'} exact className={classes.nav_link} activeClassName={classes.active_link}>Home</NavLink></li>
                                {
                                    tree && tree.map((page) => {
                                        return page.nav_visible ? <li className={classes.menu_item} key={page.url_key} ><NavLink to={'/' + page.url_key} className={classes.nav_link} activeClassName={classes.active_link}>{page.nav_name}</NavLink></li> : null;
                                    })
                                }
                            </ul>
                            <div className={classes.bottom_container}>
                                <SocialLinks />
                            </div>
                        </aside>
                    </div>
                </Drawer>
            </Portal>

        </>
    )
    
/*
    return (
        <>
            {
                <div className={classes.icon_wrapper} onClick={toggle}>
                    {
                        open
                            ?
                            <CrossIcon color="#fff" size="20" />
                            :
                            <MenuIcon color="#fff" size="20" />
                    }
                </div>
            }

            {
                windowWidth < "768" ?
                    <Portal>
                        <Drawer open={open} openClass={classes.open}>
                            <div className={classes.modal}>
                                <div className={classes.background} onClick={toggle}></div>

                                <aside className={classes.details}>
                                    <ul className={classes.menu_item_list}>
                                        <li className={classes.menu_item}><NavLink to={'/'} exact className={classes.nav_link} activeClassName={classes.active_link}>Home</NavLink></li>
                                        {
                                            tree && tree.map((page) => {
                                                return page.nav_visible ? <li className={classes.menu_item} key={page.url_key} ><NavLink to={'/' + page.url_key} className={classes.nav_link} activeClassName={classes.active_link}>{page.nav_name}</NavLink></li> : null;
                                            })
                                        }
                                    </ul>
                                    <div className={classes.bottom_container}>
                                        <SocialLinks />
                                    </div>
                                </aside>
                            </div>
                        </Drawer>
                    </Portal>

                    :

                    <div className={open ? classes.menu_wrapper_open : classes.menu_wrapper_closed} >
                 
                        <ul className={classes.menu_item_list}>
                            {
                                tree && tree.map((page) => {
                                    return page.nav_visible ? <li className={classes.menu_item} key={page.url_key}><NavLink to={'/' + page.url_key} className={classes.nav_link} activeClassName={classes.active_link}>{page.nav_name}</NavLink></li> : null;
                                })
                            }
                        </ul>
                      
                    </div>
            }
        </>
    );
    */
}

export default Menu;