import { Builder } from "@builder.io/react";

import CategorySliderModule from '../../modules/sliders/CategorySlider';
import Grid from '../../components/grid/Grid';

import classes from './CategorySlider.module.css';

export const CategorySlider = () => (
    <Grid container className={classes.root}>
        <CategorySliderModule />
    </Grid>
);

Builder.registerComponent(CategorySlider, {
    name: "Category Slider"
});