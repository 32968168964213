
import { useSelector } from 'react-redux';

const useVacancy = (id) => {

    const vacancy = useSelector((state) => {
        return state.vacancies.find((vacancy) => {
            return vacancy.id == id;
        });
    });

    return vacancy;
}

export default useVacancy;