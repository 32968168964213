import { Link } from 'react-router-dom';

import Image from '../../image/Image';
import Config from '../../../lib/config/Config';

import classes from './CategoryTile.module.css';

const CategoryTile = ({ category }) => {

    const getCategoryImage = (image) => {
        return image ? Config.get('backend').image_url + image.url : '/images/placeholder/placeholder.jpg';
    };

    return (
        <Link to={'/assortiment/' + category.key} className={classes.root}>
            
            <Image lazy responsive src={getCategoryImage(category.image)} alt={category.name} width={222} height={240} ratio="category" />
        
            <div className={classes.name}>
                { category.name }
            </div>
         
        </Link>
    );
}

export default CategoryTile;