import { Link } from 'react-router-dom';
import { FacebookIcon, TwitterIcon, InstagramIcon } from '../icons';

import classes from './SocialLinks.module.css';

const SocialLinks = () => {

    return (
        <ul className={classes.socials_wrapper}>

            <li className={classes.social_item}>
                <Link to={{ pathname: 'https://www.facebook.com/anytymenl' }} target="_blank" rel="noreferrer">
                    <FacebookIcon className={classes.icon} size="14" />
                </Link>
            </li>

            <li className={classes.social_item}>
                <Link to={{ pathname: 'https://www.instagram.com/anytyme_nederland' }} target="_blank" rel="noreferrer">
                    <InstagramIcon className={classes.icon} size="14" />
                </Link>
            </li>

        </ul>
    );
}

export default SocialLinks;