import { useMemo } from 'react';
import { format, isBefore, isAfter } from 'date-fns';

import classNames from 'classnames';

import { ClockIcon, CheckIcon } from '../../icons';

import classes from './Services.module.css';

const useCheckIsOpen = (hours, specialHours) => {

    return useMemo(() => {

        const now = new Date();
        const day = format(now, 'EEEE').toLocaleLowerCase();

        if (hours === null || hours[day] === undefined || specialHours[0] === null) {
            return false;
        }

        let openTime = hours[day].openTime;
        let closeTime = hours[day].closeTime;

        const formated_date = format(now, 'yyyy-MM-dd');
        const special = specialHours.find(({ date }) => {
            return (date === formated_date);
        });

        if (special) {

            if (special.open) {
                openTime = special.openTime;
                closeTime = special.closeTime;
            } else {
                return false;
            }

        }

        if (!hours[day].open) {
            return false;
        }

        const [ohh, omm] = openTime.split(':');
        const openingTime = new Date().setHours(ohh, omm, 0);

        const [chh, cmm] = closeTime.split(':');
        const closingTime = new Date().setHours(chh, cmm, 0);

        return !isBefore(now, openingTime) && !isAfter(now, closingTime);
    }, [hours, specialHours]);

}

const Services = ({ delivery, takeaway, regularHours = {}, specialHours = [], regularDeliveryHours = {}, specialDeliveryHours = [] }) => {
    const deliveryEanbled = useCheckIsOpen(regularDeliveryHours, specialDeliveryHours);
    const takeAwayEanbled = useCheckIsOpen(regularHours, specialHours);

    const deliveryClass = classNames(classes.icon_text, (!deliveryEanbled && classes.icon_text_disabled));
    const takeAwayClass = classNames(classes.icon_text, (!takeAwayEanbled && classes.icon_text_disabled));

    return (
        <div className={classes.root}>
            {delivery && <div className={deliveryClass}>{(takeAwayEanbled ? <CheckIcon size={16} className={classes.icon} /> : <ClockIcon size={16} className={classes.icon} />)} <strong>Bezorgen</strong></div>}
            {takeaway && <div className={takeAwayClass}>{(takeAwayEanbled ? <CheckIcon size={16} className={classes.icon} /> : <ClockIcon size={16} className={classes.icon} />)} <strong>Afhalen</strong></div>}
        </div>
    );
}

export default Services;