import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components/form';

import BuilderContent from '../../../builder/BuilderContent';
import Loading from '../../../components/loading/Loading';
import Grid from '../../../components/grid/Grid';

import useVacancies from '../../../hooks/useVacancies';

import classes from './VacancyList.module.css';

const VacancyList = ({ match }) => {
    const { vacancies=[], fetchVacancies } = useVacancies();

    useEffect(() => {
        fetchVacancies();
    }, [ fetchVacancies ]);

    return (
        <>

        <BuilderContent 
            model="page" 
            url="/vacatures"
            renderLoading={() => <Loading page />}
        />

        <Grid container>
  
            <div className={classes.root}>
                {
                    vacancies
                    &&
                    vacancies.map(({ id, title, short_description }) => {
                        return (
                            <Link to={`${match.path}/${id}`} key={'v'+id} className={classes.vacancy}>
                                <h2>{ title }</h2>
                                <span className={classes.text}>{ short_description }</span>

                                <div className={classes.button}>
                                    <Button variant="primary" >SOLLICITEER</Button>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>

        </Grid>

        </>
    );
}

export default VacancyList;