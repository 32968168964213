import { Builder } from "@builder.io/react";
import { useState, useEffect, useReducer } from 'react';

import {
    Input,
    Textarea,
    Select,
    Checkbox,
    Button,
    Form
} from "../../components/form";

import useLocations from '../../hooks/useLocations';
import useCache from '../../hooks/useCache';

import Grid from '../../components/grid/Grid';
import useMediaQuery from '../../hooks/useMediaQuery';

import Anytyme from '../../lib/api/Anytyme';

import classes from './ContactForm.module.css';

const initialState = {
    success: false,
    error: null,
    sending: false
};


const reducer = (state, action) => {
    switch (action.type) {
        case 'form/request':
            return {
                ...state,
                success: false,
                error: null,
                sending: true
            };
        case 'form/success':
            return {
                ...state,
                success: true,
                error: null,
                sending: false
            };
        case 'form/error':
            return {
                ...state,
                success: false,
                error: action.payload,
                sending: false
            };
        default:
            throw new Error();
    }
}

export const ContactForm = ({ nav }) => {
    const { locations, loading } = useLocations();
    const [inputs, setInput] = useState({ name: '', email: '', phone: '', location: '', message: '', policy: false });
    const { match } = useMediaQuery('(min-width: 768px)');
    const [state, dispatch] = useReducer(reducer, initialState);

    const cache = useCache();
    const cached_locations = cache.get('contact-locations') || [];

    useEffect(() => {
        // TODO: Compare with cached_locations
        if (locations.length > 0) {
            cache.set('contact-locations', locations);
        }

    }, [locations, cache]);


    const onSubmit = async (valid, resetForm) => {

        if (valid) {

            dispatch({ type: 'form/request' });

            try {
                await Anytyme.addContact(inputs);

                dispatch({ type: 'form/success' });

                setInput({ name: '', email: '', phone: '', location: '', message: '', policy: false });
                resetForm();
            } catch (error) {
                //console.log('Error:', error);
                dispatch({ type: 'form/error', payload: error });
            }
        }
    }

    const onChange = ({ target }) => {

        setInput({
            ...inputs,
            [target.name]: target.value
        });
    }

    return (
        <Grid container className={classes.root}>
            <Grid xsSpacing={0} smSpacing={4} container>
                <Grid sx={12}>
                    <Form onSubmit={onSubmit} className={classes.form}>
                        <Grid container>
                            {
                                state.error && <Grid xs={12} className={classes.form_field}><div className={classes.error}>Formulier kon niet worden verstuurd. Probeer het later nogmaals.</div></Grid>
                            }
                            {
                                state.success && <Grid xs={12} className={classes.form_field}><div className={classes.success}>Formulier succesvol verstuurd!</div></Grid>
                            }
                            <Grid sm={6} xs={12} className={classes.form_field}>
                                <Input name="name" label="Voor- en achternaam" onChange={onChange} value={inputs.name} required />
                                <Input type="email" name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required />
                                <Input type="tel" name="phone" label="Telefoonnummer" onChange={onChange} value={inputs.phone} />
                            </Grid>

                            <Grid sm={6} xs={12} className={classes.form_field}>

                                <Select name="location" label="Vestiging" onChange={onChange} value={inputs.location} loading={(cached_locations.length === 0 && loading)} required>
                                    <option value="">Selecteer een vestiging</option>
                                    {
                                        cached_locations.map(({ name }) => {
                                            return <option key={name} value={name}>{name}</option>
                                        })
                                    }
                                </Select>

                                <Textarea name="message" label="Bericht" onChange={onChange} value={inputs.message} required />
                            </Grid>

                            <Grid sm={6} xs={12} className={classes.form_field}>
                                <Checkbox
                                    name="policy"
                                    required
                                    onChange={onChange}
                                    value={inputs.policy}
                                    label={<> Ik ga akkoord met de <a href="/privacy-policy">privacy policy</a>.</>}
                                />
                            </Grid>

                            <Grid sm={6} xs={12} className={classes.form_field + ' ' + classes.button}>
                                <Button variant="primary" className={classes.send} loading={state.sending}>Verstuur</Button>
                            </Grid>

                        </Grid>

                    </Form>

                </Grid>

            </Grid>
        </Grid>
    );
}

Builder.registerComponent(ContactForm, {
    name: "Contact form"
});