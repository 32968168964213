import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addVacancies } from '../redux/vacancies/actions';

import Anytyme from '../lib/api/Anytyme';

import useRequest from './useRequest';

const useVacancies = () => {
    const dispatch = useDispatch();
    const vacancies = useSelector(state => state.vacancies);

    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchVacancies = useCallback( async () => {
        try {
            setRequest();

            const { data } = await Anytyme.getVacancies();

            const newData = data.reduce((data, { id, attributes }) => {

                const locations = attributes.locations.data.reduce((locations, { id, attributes }) => {

                    locations.push({
                        id: id,
                        ...attributes
                    });

                    return locations;
                }, []);

                data.push({
                    id: id,
                    ...attributes,
                    locations: locations
                });
                return data;
            }, []);

            dispatch(addVacancies(newData));
            setSuccess(newData);

            return newData;
        } catch (error) {
            console.log('[useVacancies] error', error);
            setError(error);

            return null;
        }
    }, []);

    return {
        data,
        vacancies,
        error,
        loading,
        fetchVacancies
    };
}

export default useVacancies;