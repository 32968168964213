import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Portal from '../portal/Portal';
import Button from '../form/button/Button';

import { ArrowIcon } from '../icons';

import useMediaQuery from '../../hooks/useMediaQuery';

import classes from './OrderDirect.module.css';

const OrderDirect = () => {

    const history = useHistory();
    const { favorite_location } = useSelector(state => state.app);
    const { match: mobile_visible } = useMediaQuery('(max-width: 768px)');

    const onClick = () => {
        if (favorite_location) {
            
            if (favorite_location.orderUrl) {
                window.open(favorite_location.orderUrl, '_blank');
            } else {
                history.push('/vestigingen/'+ favorite_location.key);
            }

        } else {
            history.push('/vestigingen');
        }
    }

    if (!mobile_visible) { return null; }

    return (
        <Portal>
            <div className={classes.root}>
                <Button
                    type="button"
                    className={classes.button}
                    onClick={onClick}
                >
                    <span>
                        <span className="text-large">Direct bestellen</span><br/>
                        { favorite_location ? <span className="text-small text-normal">bij { favorite_location.name }</span> : null }
                    </span>
                    <ArrowIcon size={12} className={classes.icon} />
                </Button>
            </div>
        </Portal>
    );
}

export default OrderDirect;