import { Link } from 'react-router-dom';

import Image from '../../image/Image';
import Config from '../../../lib/config/Config';

import classes from './ProductTile.module.css';

const ProductTile = ({ product, category='' }) => {

    const getProductImage = (image) => {
        return image ? Config.get('backend').image_url + image.url : '/images/placeholder/placeholder.jpg';
    };

    const price = (product.price !== 0 ? Number(product.price).toFixed(2).replace('.', ',') : null)

    return (
        <Link to={'/assortiment/' + category + '/' + product.sku} className={classes.root}>
  
                <div className={classes.image}>
                    <Image lazy responsive src={getProductImage(product.image)} alt={product.name} width={350} height={318} ratio="product" />
                    {
                        (product.popular && price !== null)
                        &&
                        <div className={classes.price}>{ price }</div>
                    }
                </div>

                <div className={classes.name}>
                    { product.name }
                </div>

                {
                    product.popular
                    &&
                    <div className={classes.label}>Populair</div>
                }
                
      
        </Link>
    );
}

export default ProductTile;