import classNames from 'classnames';

import classes from './Grid.module.css';

const Grid = ({ children, container=false, nesting=false, first=false, spacing=0, style={}, className: gridClassName=null, onClick=null, ...restProps }) => {

    let classnames = {
        [classes['container']]: container,
        [classes['grid']]: !container,
    }

    if (classes['spacing-'+spacing] !== undefined) { classnames[classes['spacing-'+spacing]] = true; }

    const { xsSpacing=null, smSpacing=null, mdSpacing=null, lgSpacing=null, xlSpacing=null } = restProps;

    if (classes['spacing-xs-'+xsSpacing] !== undefined) { classnames[classes['spacing-xs-'+xsSpacing]] = true; }
    if (classes['spacing-sm-'+smSpacing] !== undefined) { classnames[classes['spacing-sm-'+smSpacing]] = true; }
    if (classes['spacing-md-'+mdSpacing] !== undefined) { classnames[classes['spacing-md-'+mdSpacing]] = true; }
    if (classes['spacing-lg-'+lgSpacing] !== undefined) { classnames[classes['spacing-lg-'+lgSpacing]] = true; }
    if (classes['spacing-xl-'+xlSpacing] !== undefined) { classnames[classes['spacing-xl-'+xlSpacing]] = true; }

    if (!container || nesting) { 

        const { xs=12, sm=0, md=0, lg=0, xl=0 } = restProps;

        if (classes['grid-xs-'+xs] !== undefined) { classnames[classes['grid-xs-'+xs]] = true; }
        if (classes['grid-sm-'+sm] !== undefined) { classnames[classes['grid-sm-'+sm]] = true; }
        if (classes['grid-md-'+md] !== undefined) { classnames[classes['grid-md-'+md]] = true; }
        if (classes['grid-lg-'+lg] !== undefined) { classnames[classes['grid-lg-'+lg]] = true; }
        if (classes['grid-xl-'+xl] !== undefined) { classnames[classes['grid-xl-'+xl]] = true; }
        
        const { xsOrder=null, smOrder=null, mdOrder=null, lgOrder=null, xlOrder=null } = restProps;

        if (classes['order-xs-'+xsOrder] !== undefined) { classnames[classes['order-xs-'+xsOrder]] = true; }
        if (classes['order-sm-'+smOrder] !== undefined) { classnames[classes['order-sm-'+smOrder]] = true; }
        if (classes['order-md-'+mdOrder] !== undefined) { classnames[classes['order-md-'+mdOrder]] = true; }
        if (classes['order-lg-'+lgOrder] !== undefined) { classnames[classes['order-lg-'+lgOrder]] = true; }
        if (classes['order-xl-'+xlOrder] !== undefined) { classnames[classes['order-xl-'+xlOrder]] = true; }
    }

    if (first) {
        classnames[classes['first']] = true;
    }

    const className = classNames(classnames) + (gridClassName !== null ? ' ' + gridClassName : '');

    return (
        <div className={ className } style={ style } onClick={ onClick }>
            { children }
        </div>
    );
}

export default Grid;