import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = [];

export default createReducer(initialState, {
    [actions.addVacancies]: (state, action) => {
        const { payload } = action;
        
        return payload
    }
});
