import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';


const initialState = {
    favorite_location: null,
    cookie_consent: null
};


export default createReducer(initialState, {
    [actions.addFavoriteLocation]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            favorite_location: payload
        }
    },
    [actions.addCookieConsent]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            cookie_consent: payload
        }
    }
});
