import BuilderContent from '../../builder/BuilderContent';
import Loading from '../../components/loading/Loading';
import OrderDirect from '../../components/order_direct/OrderDirect';

const Home = () => {
    return (
        <>
            <BuilderContent 
                model="page"
                url="/home_rd"
                renderLoading={() => <Loading page />}
            />
            <OrderDirect />
        </>
    );

}

export default Home;