import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addCategories } from '../redux/categories/actions';

import Anytyme from '../lib/api/Anytyme';


const sortCategories = (categories) => {
    categories.sort((a, b) => {
        return a.sequence - b.sequence;
    });
    return categories;
};

const useCategories = () => {
    
    const dispatch = useDispatch();
    const { data, error, loading } = useSelector(state => state.categories);
    
    const categories = useMemo(() => {
        return data || null;
    }, [data]);

    useEffect(() => {

        let cancelRequest = false;

        const fetchData = async () => {

            dispatch(addCategories.request());

            try {
                const { data=[] } = await Anytyme.getCategories();
   
                const categories = data.map(({ id, attributes }) => {

                    let products = [];
                    if (attributes?.products?.data) {
               
                        products = attributes.products.data.map(({ id, attributes }) => {
                    
                            let image = null;
                  
                            if (attributes?.image?.data) {
                                image = {
                                    id: attributes.image.data.id,
                                    ...attributes.image.data.attributes
                                }
                            }
                         
                            return {
                                id: id,
                                ...attributes,
                                image: image
                            }
                        });

                    }

                    let image = null;

                    if (attributes?.image?.data) {
                        image = {
                            id: attributes.image.data.id,
                            ...attributes.image.data.attributes
                        }
                    }

                    return {
                        id: id,
                        ...attributes,
                        products: products,
                        image: image
                    }
                });

                const categories_sorted = categories ? sortCategories(categories) : null;
                if (!cancelRequest) { dispatch(addCategories.success(categories_sorted)); }
            } catch (error) {
                if (!cancelRequest) { dispatch(addCategories.error(error)); }
            }
        };

        if (categories === null) {
            fetchData();
        }

        return () => {
            cancelRequest = true;
        };
    }, [categories, dispatch]);


    return {
        categories,
        error,
        loading
    };
}

export default useCategories;