import Grid from '../grid/Grid';
import FooterLinks from './footer-links/FooterLinks';
import SocialLinks from '../social-links/SocialLinks';
import Terms from './terms/Terms';
import Copyright from './copyright/Copyright';


import classes from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <Grid container>
                <Grid sm={6} className={classes.top_container}>
                    <FooterLinks />
                </Grid>
                <Grid sm={6} className={classes.bottom_container} >
                    <SocialLinks />
                    <Terms />
                    <Copyright />
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;