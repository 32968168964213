import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import Card from '../card/Card';
import Grid from '../grid/Grid';
import Button from '../form/button/Button';

import { StarFullIcon, ArrowIcon } from '../icons';

import Open from './open/Open';
import Services from './services/Services';

import useMediaQuery from '../../hooks/useMediaQuery';

import classes from './Favorite.module.css';

import FavoriteButton from './favoritebutton/FavoriteButton';

const Favorite = ({ location }) => {
    const { favorite_location } = useSelector(state => state.app);
    const { match } = useMediaQuery('(min-width: 768px)');
    const history = useHistory();

    if (favorite_location === null || favorite_location !== null && (!match && location.pathname !== '/')) {
        return null;
    }

    const { key, name, regularHours, specialHours, regularDeliveryHours, specialDeliveryHours, delivery, takeaway, orderUrl } = favorite_location;

    const toStore = () => {
        history.push('/vestigingen/' + key, { location: favorite_location })
    }

    return (
        <Grid container className={classes.root}>
            <Card className={classes.card}>
                <Grid container>

                    <Grid lg={7} sm={4} sx={12} className={classes.row}>
                        <Grid container>

                            <Grid lg={6} xs={12} className={classes.row}>
                                <div className={classes.star}>
                                    <FavoriteButton location={favorite_location} />
                                </div>

                                <div className={classes.heading_container}>
                                    <span className={classes.favourite_phrase}>Favoriete vestiging</span>
                                    <h3 className={classes.heading}>{name}</h3>
                                </div>
                            </Grid>

                            <Grid lg={6} xs={12} className={classes.row}>
                                <Open regularHours={regularHours} specialHours={specialHours} className={classes.open} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid lg={5} sm={8} sx={12} className={classes.row}>
                        <Grid container>

                            <Grid xs={6} className={classes.row}>
                                <Services
                                    delivery={delivery}
                                    takeaway={takeaway}
                                    regularHours={regularHours}
                                    specialHours={specialHours}
                                    regularDeliveryHours={regularDeliveryHours}
                                    specialDeliveryHours={specialDeliveryHours}
                                />
                            </Grid>

                            <Grid xs={6} className={classes.button}>
                                {
                                    orderUrl !== ''
                                        ?

                                        <>
                                            {
                                                match
                                                    ?
                                                    <Button variant="secondary" className={classes.order_button} onClick={() => { window.open(orderUrl, '_blank'); }}>
                                                        <span>
                                                            <span className={classes.button_order_label}>Direct bestellen</span>
                                                            <span className={classes.button_store_name}>bij {name}</span>
                                                        </span>
                                                        <ArrowIcon size={12} className={classes.order_button_icon} />
                                                    </Button>
                                                    :
                                                    <Button variant="primary" onClick={toStore} className={classes.order_button}>
                                                        Bestellen
                                                    </Button>
                                            }
                                        </>
                                        :
                                        <Button variant="primary" onClick={toStore}>
                                            Bestellen
                                        </Button>
                                }

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}

export default Favorite;