import { useState } from 'react';

import { Marker as GMMarker, InfoWindow } from '@react-google-maps/api';

import { Button } from '../form';

import classes from './Marker.module.css';

const Marker = ({ title, position, street, number, postalcode, city, onButtonClick=()=>{} }) => {
    const [ showInfo, setShowInfo ] = useState(false)

    return (
        <GMMarker 
            title={ title }
            position={ position }
            onClick={() => { setShowInfo(true); }}
        >
            {
                showInfo
                &&
                <InfoWindow
                    position={ position }
                    visible={ false }
                    onCloseClick={() => { setShowInfo(false); }}
                >
                    <div className={classes.info}>
                        <h4>{ title }</h4>
                        <p>
                            { street } { number }<br/>
                            { postalcode } { city }<br/>
                        </p>
                        <Button className={classes.button} onClick={ onButtonClick }>Meer info</Button>
                    </div>
                </InfoWindow>
            }
        </GMMarker>
        )
}

export default Marker;