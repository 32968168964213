import { useState, useEffect } from 'react';

import classNames from 'classnames';

import SearchBar from '../../../components/searchbar/SearchBar';
import Toggle from '../../../components/toggle/Toggle';
import Loading from '../../../components/loading/Loading';
import MyLocation from '../../../components/locations/mylocation/MyLocation';

import Location from './location/Location';

import classes from './List.module.css';

const List = ({ locations = null, onShowContent = null, loading = false, onSearch, onSearchLatLon }) => {
    const [show, setShow] = useState('list');

    const contentClasses = classNames(classes.content, classes[show]);

    useEffect(() => {
        if (onShowContent) { onShowContent((show === 'list')) }
    }, [show, onShowContent])

    const onToggle = (value) => {
        setShow(value);
    }

    return (
        <div className={classes.root}>

            <div className={classes.header}>

                <h2>Zoek een vestiging</h2>

                <SearchBar
                    label="Zoeken"
                    placeholder="postcode of plaats"
                    onSearch={onSearch}
                />

                <MyLocation
                    onLocation={({ latitude, longitude }) => { onSearchLatLon(latitude, longitude) }}
                    className={classes.mylocation}
                />
            </div>

            <div className={classes.toggle}>
                <Toggle
                    values={[
                        { label: 'Lijst', value: 'list' },
                        { label: 'Kaart', value: 'map' }
                    ]}
                    selected="list"
                    onChange={onToggle}
                />
            </div>

            <div className={contentClasses}>

                {
                    loading
                        ?
                        <Loading />
                        :
                        <>
                            {
                                locations.length === 0
                                    ?
                                    <div className={classes.no_result}> <p className="small">Geen resultaat.</p></div>
                                    :
                                    locations.map((location) => {
                                        return <Location key={location.storeCode} data={location} />
                                    })
                            }
                        </>
                }
              
            </div>
        </div>
    )
}

export default List;