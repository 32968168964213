import IconWrapper from '../wrapper/IconWrapper';

const ClockIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.392" height="16.392" viewBox="0 0 16.392 16.392">
                <g transform="translate(0.428 0.427)">
                    <path d="M14.392,7.2A7.2,7.2,0,1,1,7.2,0,7.2,7.2,0,0,1,14.392,7.2Z" transform="translate(0.572 0.573)" fill="none" stroke="#b1b7c0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                    <path d="M2.575,5.34,0,3.724V0" transform="translate(7.597 4.666)" fill="none" stroke="#b1b7c0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default ClockIcon;