import { useState, useEffect } from 'react';

import classNames from 'classnames';

import CrossIcon from '../icons/icons/CrossIcon';

import useQuery from '../../hooks/useQuery';

import classes from './SearchBar.module.css';

const SearchBar = ({ label = 'Search', placeholder = ' ', onChange = null, onSearch = null, className = '' }) => {
    let query = useQuery();

    const [value, setValue] = useState(query.get("locatie") ? query.get("locatie") : '');
    const formClasses = classNames(classes.root, className);

    useEffect(() => {
        value && onSearch(value)
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();

        if (onSearch !== null) { onSearch(value); }
    }

    const onInputChange = ({ target: { value } }) => {
        setValue(value);
        if (onChange !== null) { onChange(value); }
    }

    const onClear = () => {
        setValue('');
        if (onSearch !== null) { onSearch(''); }
    }

    return (
        <form className={formClasses} onSubmit={onSubmit}>
            <div className={classes.input_container}>
                <input
                    className={classes.input}
                    type="search"
                    name="search_input"
                    value={value}
                    placeholder={placeholder}
                    onChange={onInputChange}
                />

                <div className={classes.clear} onClick={onClear}><CrossIcon size={8}/></div>
            </div>
            <button
                className={classes.button}
                type="submit"
            >
                {label}
            </button>

        </form>
    );

}

export default SearchBar;