import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './routes/LayoutRoute';

import Home from '../../pages/home/Home';
import Builder from '../../pages/builder/Builder';
import Products from '../../pages/products/Products';
import Locations from '../../pages/locations/Locations';
import Newsletter from '../../pages/newsletter/Newsletter';
import Popular from '../../pages/popular/Popular';
import Vacancies from '../../pages/vacancies/Vacancies';

import ScrollToTop from '../../components/scroll/ScrollToTop';

import useNavigation from '../../hooks/useNavigation';

const components = {
    builder: Builder,
    products: Products,
    locations: Locations,
    newsletter: Newsletter,
    popular: Popular,
    vacancies: Vacancies
};


const Router = () => {
    const { pages } = useNavigation();

    if (pages === null) { return null; }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
                {
                    pages.map(({ url_key, type, seo_title, seo_description, nav_name, nav_visible }) => {
          
                        return (
                            <Route
                                //exact
                                key={url_key}
                                path={'/' + url_key}
                                component={components[type]}
                                seo={{
                                    title: seo_title,
                                    description: seo_description
                                }}
                                nav={{
                                    name: nav_name,
                                    visible: nav_visible
                                }}
                            />
                        );
                    })
                }
                <Route path="*" component={Builder} />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;