import { useState } from 'react';

import classNames from 'classnames';

import classes from './Checkbox.module.css';

const Checkbox = ({ name="", value=false, label='', placeholder=' ', error=false, required=false, onChange=()=>{}, renderLabel=null, ...rest_props }) => {
    const [checked, setChecked] = useState(value);

    const inputClasses = classNames(classes.checkbox, (error && classes.checkbox_error));

    const onInputChange = ({ target: { name, checked }}) => {

        setChecked(checked);

        onChange({
            target: {
                name: name,
                value: checked,
            },
        });
    }

    return (
        <div className={classes.field}>

            <input 
                type="checkbox"
                className={classes.checkbox_input}
                name={name}
                id={name}
                onChange={onInputChange} 
                required={required}
                
                {...rest_props}
            />

            <label htmlFor={name} className={classes.label}>

                <div className={inputClasses} >
                    { checked && <span className={classes.checked}/>}
                </div>

                <span>{ label }</span>

            </label>

        </div>
    );
    
}

export default Checkbox;