import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import ReactGA from "react-ga4";

import Config from '../../lib/config/Config';


const GoogleAnalytics = () => {
    const location = useLocation();
    const { cookie_consent } = useSelector(state => state.app);

    useEffect(() => {
        if (cookie_consent !== true) { return null; }

        ReactGA.initialize(Config.get('ga').ga_id);

        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    }, [cookie_consent, location]);

    return null;
}

export default GoogleAnalytics;