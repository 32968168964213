import { Builder, withChildren } from "@builder.io/react";

import CardComponent from '../../components/card/Card';
import Grid from '../../components/grid/Grid';

export const Card = props => {
    return (
        <Grid spacing={4} container>
            <CardComponent style={{ padding: props.Padding + 'px' }}>
                {
                    props.children ?
                        props.children
                        :
                        'Add content'
                }
            </CardComponent>
        </Grid>
    );
}


Builder.registerComponent(withChildren(Card), {
    name: "Card",
    inputs: [
        {
            type: 'string',
            name: 'Padding',
            required: true,
            enum: [
                {
                    label: 'Padding 15',
                    value: '15',
                },
                {
                    label: 'Padding 20',
                    value: '20',
                },
                {
                    label: 'Padding 30',
                    value: '30',
                }
            ],
            defaultValue: '15',
        },
    ],
});
