import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';

import Config from './lib/config/Config';
import Anytyme from './lib/api/Anytyme';

import App from './App';
import configureStore from './redux/store';
import initBuilder from './builder';

import './css/main.css';


const { store, persistor } = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <PersistGate 
                loading={null} 
                persistor={ persistor }
                onBeforeLift={async () => {
                    return new Promise(async (resolve) => {
                        await Config.init();
                        
                        initBuilder();
                        
                        const anytymen = new Anytyme(Config.get('backend'));

                        resolve();
                    })
                }}
            >
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </PersistGate>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('app')
);