import IconWrapper from '../wrapper/IconWrapper';

const CrossIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.372" height="9.372" viewBox="0 0 9.372 9.372">
                <line id="Line_6" data-name="Line 6" x1="9.254" transform="translate(1.414 1.414) rotate(45)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                <line id="Line_16" data-name="Line 16" x1="9.254" transform="translate(1.414 7.958) rotate(-45)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
            </svg>
        </IconWrapper>
    );
}

export default CrossIcon;


