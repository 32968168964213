import defaultConfig from '../../config/default.json';

let instance = null;

class Config {

    constructor() {
        if(!instance){
            instance = this;
        }
    }

    init = async () => {
        this.config = defaultConfig;
        return this.parseEnv();
    }

    parseEnv = async () => {

        return new Promise(async (resolve) => {
            if (process) {
                if (Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_ENVIRONMENT')) {
                    try {
                        const override = await import('../../config/'+process.env.REACT_APP_ENVIRONMENT+'.json');
                        this.config = this.mergeDeep(this.config, override.default);
                    } catch (error) {
                        console.log('[Config] Override ' + error);
                    }
                }
        
                Object.entries(process.env).forEach(([key, value]) => {
                    if (key.includes('REACT_APP_')) {
                        const config_key = key.replace('REACT_APP_', '').toLowerCase();
        
                        if (Object.prototype.hasOwnProperty.call(this.config, config_key)) {
                            this.config[config_key] = value;
                        }
                    }
                });
    
            }

            resolve();
        })

    }

    set = (key, value) => {
        this.config[key] = value;
    }
    
    get = (key) => {
        return this.config[key];
    }
    
    getAll = () => {
        return this.config;
    }

    mergeDeep = (...objects) => {
        const isObject = obj => obj && typeof obj === 'object';
        
        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach((key) => {
                const pVal = prev[key];
                const oVal = obj[key];
                
                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal);
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.mergeDeep(pVal, oVal);
                } else {
                    prev[key] = oVal;
                }
            });
            
            return prev;
        }, {});
    }

}

const config = new Config();

export default config;

export const useConfig = (key) => {
    return config.get(key);
}