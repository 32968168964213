import React from "react";

import { LoaderIcon } from '../../icons';

import classNames from 'classnames';

import classes from './Select.module.css';

const Select = ({ name="", type='text', children=null, value="", label='', placeholder=' ', error=false, required=false, loading=false, onChange=()=>{}, ...rest_props }) => {

    const inputClasses = classNames(classes.select, (error && classes.select_error));

    return (
        <div className={classes.field}>
            { label !== '' && <label htmlFor={name} className={classes.label}>{ label }</label> }

            { 
                loading     
                ?
                    <div className={classes.loading} >
                        <LoaderIcon />
                    </div>
                :          
                    <select 
                        className={inputClasses} 
                        name={name} 
                        value={value}
                        required={required} 
                        onChange={onChange} 
                        {...rest_props}
                    >
                        { children }
                    </select>

            }

        </div>
    );
    
}

export default Select;