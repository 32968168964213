import IconWrapper from '../wrapper/IconWrapper';

const StarFullIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.697" height="19.604" viewBox="0 0 20.697 19.604">
                <path d="M11.125.48l2.522,5.074a.87.87,0,0,0,.653.476l5.647.816a.869.869,0,0,1,.575.333A.838.838,0,0,1,20.43,8.3l-4.093,3.958a.83.83,0,0,0-.246.763l.98,5.585a.857.857,0,0,1-.71.972.945.945,0,0,1-.562-.087l-5.03-2.637a.845.845,0,0,0-.808,0L4.895,19.508a.884.884,0,0,1-1.173-.359.867.867,0,0,1-.088-.545l.98-5.585a.858.858,0,0,0-.246-.762L.253,8.3a.856.856,0,0,1,0-1.211l0,0a.99.99,0,0,1,.492-.242l5.648-.816a.884.884,0,0,0,.653-.477L9.567.48a.857.857,0,0,1,.5-.436.869.869,0,0,1,.664.048A.893.893,0,0,1,11.125.48Z" transform="translate(0 0)" fill="#fdc619"/>
            </svg>
        </IconWrapper>
    );
}

export default StarFullIcon;