import { NavLink } from 'react-router-dom';

import Grid from '../grid/Grid';

import classes from './CategoryList.module.css';

const CategoryList = ({ categories }) => {

    if (categories === null) { return null; }

    return (
        <Grid>
            <div className={classes.root}>
                {
                    categories.map((category) => {
                        return (
                            <div key={category.key} className={classes.item}>
                                <NavLink to={category.key} className={classes.link} activeClassName={classes.active_link}>{category.name}</NavLink>
                            </div>
                        )
                    })
                }
            </div>
        </Grid>
    );
}

export default CategoryList;