import { Switch, Route } from 'react-router-dom';

import VacancyList from './vacancylist/VacancyList';
import Vacancy from './vacancy/Vacancy';

const Vacancies = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <Route path={path} exact component={VacancyList}  />
            <Route path={path + '/:id'} exact component={Vacancy} />
        </Switch>
    );

}

export default Vacancies;