import Router from './modules/router/Router';
import useCategories from './hooks/useCategories';

const App = () => {

    // Load all category data
    useCategories();

    return (
        <>
            <Router />
        </>
    );
}

export default App;