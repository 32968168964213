import { Switch, Route } from 'react-router-dom';

import LocationsList from './LocationsList';
import LocationDetails from './LocationDetails';

const Locations = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <Route path={path} exact component={LocationsList} />
            <Route path={path + '/:location_key'} exact component={LocationDetails} />
        </Switch>
    );

}

export default Locations;