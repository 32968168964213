import Card from '../../components/card/Card';
import Navigation from '../../components/header/navigation/Navigation';
import Image from '../../components/image/Image';

import classes from './NotFound.module.css';

const NotFound = () => {
    return (
        <div className="container-small">
            <Card className={classes.card}>
                <Image className={classes.image} lazy responsive src="/images/404-image.jpg" width={708} height={400} ratio="cms_overview" />

                <div className={classes.not_found_text}>
                    <h1>Oops!</h1>
                    <p>Sorry, de pagina die je zoekt kan niet worden gevonden.</p>
                    <p className={classes.suggestion}>Probeer de volgende suggesties:</p>

                    <div className={classes.nav_wrapper}>
                        <Navigation />
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default NotFound;