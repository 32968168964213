import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';


const initialState = {

    pages: null
};


export default createReducer(initialState, {
    [actions.addNavigation]: (state, action) => {
        const { payload } = action;
        
        return {
            ...state,
            pages: payload
        }
    }
});
