import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useCategory from '../../hooks/useCategory';
import useMediaQuery from '../../hooks/useMediaQuery';

import Config from '../../lib/config/Config';

import Grid from '../../components/grid/Grid';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Image from '../../components/image/Image';
import ProductList from '../../components/product/ProductList';
import ProductTile from '../../components/product/tile/ProductTile';
import OrderDirect from '../../components/order_direct/OrderDirect';

import classes from './Popular.module.css';

const useProducts = (category) => {

    return useMemo(() => {

        if (!category) { return []; }

        const products = [...category.products];

        const first_product = products.shift();

        return {
            first_product: first_product,
            products: products
        };

    }, [category])
}

const Popular = ({ nav }) => {
    const { category } = useCategory('populair');
    const { first_product, products } = useProducts(category);
    const { match } = useMediaQuery('(min-width: 768px)');

    const renderItem = (product) => {
        return <ProductTile product={product} category={'populair'} />;
    }

    const getProductImage = ({ hero_image, image }) => {

        let url = '/images/placeholder/placeholder.jpg';

        if (hero_image) {
            url = Config.get('backend').image_url + hero_image.url;
        } else if (image) {
            url = Config.get('backend').image_url + image.url;
        }

        return url;
    };

    const price = (first_product && first_product.price !== 0 ? Number(first_product.price).toFixed(2).replace('.', ',') : null)

    if (category === null) { return null; }

    return (
        <>
            <Grid container>

            {
                match
                &&
                <Grid xsSpacing={5} smSpacing={4} container>
                    <Grid>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            sufix={[{ label: nav.name }]}
                        />
                    </Grid>
                </Grid>
            }


                <Grid xsSpacing={5} smSpacing={4} container>
                    <Grid>
                        <Grid className={classes.intro} container>
                            <Grid xs={12} sm={9}>
                                <h1>{ category.name }</h1>
                                <p>{ category.description }</p>
                            </Grid>
                        </Grid>
                        {
                            first_product
                            &&
                            <Link to={'/assortiment/populair/' + first_product.sku} className={classes.link}>
                        
                                <Grid xsSpacing={0} smSpacing={3} container className={classes.info_container}>
                                    
                                    <Grid xs={12} sm={6} >
                                        <div className={classes.image_container}>
                                            <Image lazy responsive src={getProductImage(first_product) } alt={first_product.name} ratio="popular" className={classes.image} />
                                            {
                                                first_product.popular
                                                &&
                                                <>
                                                    <div className={classes.label}>Populair</div>
                                                    {
                                                        price !== null
                                                        &&
                                                        <div className={classes.price}>{ price }</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={6} className={classes.description_container}>
                                        <h3>{ first_product.name }</h3>
                                        <p>{ first_product.description }</p>
                                    </Grid>            

                                </Grid>
                            
                            </Link>
                        }
                    </Grid>
                </Grid>

                {
                    (products)
                    &&
                    <Grid xsSpacing={3} smSpacing={0} container>
                        <Grid>
                            <ProductList
                                products={products}
                                renderItem={renderItem}
                            />
                        </Grid>
                    </Grid>
                }
                
                
            </Grid>

            <OrderDirect />
        </>
    );
}

export default Popular;