import classes from './Copyright.module.css';

const Copyright = () => {
    return (
        <div className={classes.copyright}>
            © AnyTyme {(new Date().getFullYear())}
        </div>
    );
}

export default Copyright