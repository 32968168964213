import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';


const initialState = {

    data: null,
    error: null,
    loading: false
};


export default createReducer(initialState, {
    [actions.addCategories.request]: (state, action) => {
        
        state.error = null;
        state.loading = true;
    },
    [actions.addCategories.success]: (state, action) => {
        
        const { payload } = action;
        
        state.data = payload;
        state.error = null;
        state.loading = false;
    },
    [actions.addCategories.error]: (state, action) => {
        
        const { payload } = action;
        
        state.error = payload;
        state.loading = false;
    }
});
