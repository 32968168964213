import { Link } from 'react-router-dom';

import classes from './Terms.module.css';

const Terms = () => {
    const terms = [
        {
            'name': 'Disclaimer',
            'url': '/disclaimer'
        }, {
            'name': 'Privacy policy',
            'url': '/privacy-policy'
        }
    ]
    return (
        <div>
            {
                terms.map(term => <Link to={term.url} className={classes.terms_link} key={term.name}>{term.name}</Link>)
            }
        </div>
    );
}

export default Terms;