import { addCookieConsent } from '../../redux/application/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Card from '../card/Card';
import Portal from '../portal/Portal';
import Grid from '../grid/Grid';
import Button from '../form/button/Button';

import classes from './CookieConsent.module.css'

const CookieConsent = () => {
    const dispatch = useDispatch();
    const { cookie_consent } = useSelector(state => state.app);

    const onButtonClick = (value) => {
        dispatch(addCookieConsent(value));
    }

    if (cookie_consent !== null) { return null; }

    return (
        <>
            <Portal>
                <Card className={classes.root}>
                    <Grid container className={classes.cookie_container}>
                        <Grid sm={8}>
                            <div className={classes.text}>
                                <div className="text-bold">
                                    Deze website maakt gebruik van cookies
                                </div>
                                <div className="text-small">
                                    AnyTyme maakt gebruik van cookies en vergelijkbare technieken. AnyTyme.nl gebruikt functionele, analytische en marketing cookies en verzamelt daarmee informatie over het gebruik van de website om u een optimale gebruikerservaring te bieden. <Link className={classes.cookie_link} to="/cookies">Klik hier</Link> voor informatie over ons cookiebeleid. Wanneer u op ‘Accepteer cookies' klikt, geeft u toestemming voor het plaatsen van cookies. Wilt u geen cookies toestaan? Kies dan voor 'weigeren’.
                                </div>
                            </div>
                        </Grid>

                        <Grid sm={4}>
                            <div className={classes.buttons_wrapper}>
                                <Button variant="normal" onClick={() => onButtonClick(false)}>
                                    <span>Weigeren</span>
                                </Button>

                                <Button variant="primary" onClick={() => onButtonClick(true)}>
                                    <span>Accepteren</span>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </Portal>
        </>
    );
}

export default CookieConsent;