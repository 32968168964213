import NotFound from '../not_found/NotFound';
import Loading from '../../components/loading/Loading';
import Grid from '../../components/grid/Grid';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

import { useState, useEffect } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';

import BuilderContent from '../../builder/BuilderContent';

import HomeBanner from '../../builder/homebanner/HomeBanner';

import classes from './Builder.module.css';

const Builder = ({ location: { pathname } }) => {
    const { match } = useMediaQuery('(min-width: 768px)');
    const [breadcrumbLabel, setBreadcrumbLabel] = useState('');

    useEffect(() => {
        setBreadcrumbLabel(pathname.split('/')[1].charAt(0).toUpperCase() + pathname.split('/')[1].split('-').join(' ').slice(1));
        return () => {
            setBreadcrumbLabel('');
        }
    }, []);

    return (
        <>
            {
                match
                &&
                <Grid xsSpacing={5} smSpacing={4} container>
                    <Grid>
                        <Breadcrumb
                            prefix={[{ label: 'Home', path: '/' }]}
                            sufix={[{ label: breadcrumbLabel }]}
                        />
                    </Grid>
                </Grid>
            }

            <Grid container className={classes.root}>
                <BuilderContent
                    model="page"
                    url={pathname}
                    renderLoading={() => <Loading page />}
                    renderNotFound={() => <NotFound />}
                />
            </Grid>
        </>
    );
}

export default Builder;