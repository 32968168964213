import IconWrapper from '../wrapper/IconWrapper';

const ArrowIcon = ({ size=24, color=null, direction='right',className=null }) => {

    let directions = {
        right: 'translate(0 9.049) rotate(-90)',
        left: 'translate(0 9.049) rotate(-90)',
        top: 'translate(1 10.462) rotate(-90)',
        bottom: 'translate(13 1.413) rotate(90)'
    }

    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11.876" viewBox="0 0 14 11.876">
                <g transform="translate(1 1.413)">
                    <g transform={(directions[direction] ? directions[direction] : directions['right'] )}>
                        <path d="M0,12V0" transform="translate(4.525)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                        <path d="M9.049,0,4.525,4.84,0,0" transform="translate(0 7.16)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                    </g>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default ArrowIcon;