import { NavLink } from 'react-router-dom';

import useNavigation from '../../../hooks/useNavigation';
import classes from './FooterLinks.module.css';

const FooterLinks = () => {
    const { tree } = useNavigation();

    return (
        <ul className={classes.footer_links_wrapper}>
            <li className={classes.list_item}><NavLink to={'/'} exact className={classes.nav_link}>Home</NavLink></li>
            {
                tree && tree.map((page) => {
                    return page.nav_visible ? <li className={classes.list_item} key={page.url_key}><NavLink to={'/' + page.url_key} className={classes.nav_link}>{page.nav_name}</NavLink></li> : null;
                })
            }
        </ul>
    );
}

export default FooterLinks;