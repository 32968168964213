import { builder } from '@builder.io/react/lite';

import Config from '../lib/config/Config';

import './categoryslider/CategorySlider';
import './productslider/ProductSlider';
import './card/Card';
import './locationsearch/LocationSearch';
import './bannertext/BannerText';
import './threecolumnbanner/ThreeColumnBanner';
import './twocolumnbanner/TwoColumnBanner';
import './contactform/ContactForm';
import './franchiseform/FranchiseForm';
import './actionbanner/ActionBanner';

const initBuilder = () => {
    builder.init(Config.get('builder.io').api_key);

}

export default initBuilder;