import { useState, useEffect } from 'react';

import classNames from 'classnames';

import classes from './Toggle.module.css';

const Toggle = ({values=[], selected='', onChange=null }) => {
    const [selectedValue, setSelected] = useState(selected);

    useEffect(() => {
        if (onChange !== null) { onChange(selectedValue) }
    }, [selectedValue, onChange])
 
    return (
        <div className={classes.root}>
            {
                values.map(({ label, value }) => {
                    const buttonClasses = classNames(classes.button, (value === selectedValue && classes.selected) );
                    return (
                        <button 
                            key={value}
                            className={buttonClasses} 
                            onClick={() => { setSelected(value) }}
                        >
                            { label }
                        </button>
                    )
                })
            }
        </div>
    );
    
}

export default Toggle;