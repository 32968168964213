import { Builder, Image } from "@builder.io/react";

import Grid from '../../components/grid/Grid';

import classes from './ActionBanner.module.css';

const ActionBanner = (props) => {
    const { titlePrefix, title, text, linkText, image } = props;

    return (
        <Grid spacing={4} container className={classes.root}>
            <Grid xs={12} container className={classes.card}>
                <Grid xs={12} sm={4} className={classes.text_container}>

                    <strong className={classes.title_prefix}>
                        { titlePrefix }
                    </strong>

                    <h2 className={classes.title}>
                        { title }
                    </h2>

                    <span className={classes.text}>
                        { text }
                    </span>

                    <strong className={classes.linkText}>
                        { linkText }
                    </strong>
                </Grid>

                <Grid xs={12} sm={8}>
                    <Image lazy image={image} className={classes.image} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ActionBanner;

Builder.registerComponent(ActionBanner, {
    name: "Actie Banner",
    inputs: [
        {
            name: 'titlePrefix',
            type: 'string',
            defaultValue: 'Your title prefix here',
        },
        {
            name: 'title',
            type: 'string',
            defaultValue: 'Your title here',
        },
        {
            name: 'text',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'linkText',
            type: 'string',
            defaultValue: 'Your link text here',
        },
        {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
    ],
});
