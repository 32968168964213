import classNames from 'classnames';

import classes from './Slider.module.css';

const Slider = ({ items, renderItem, itemCount=6 }) => {

    const slideClassName = classNames(classes.slide, classes['count-' + itemCount]);

    if (items === null) { return null; }

    return (
        <div className={classes.root}>
            {
                items.slice(0,itemCount).map((item, index) => {
                    return (
                        <div key={index} className={slideClassName}>
                            { renderItem(item, index)}
                        </div>
                    );
                })
            }
        </div>
    );
}

export default Slider;