import { useReducer, useCallback } from 'react';

const initialState = {
    success: false,
    error: null,
    loading: false,
    data: null
};

const reducer = (state, action) => {
    switch (action.type) {
    case 'request':
        return  {
            ...state,
            error: null,
            loading: true,
            success: false
        };
    case 'success':
        return {
            ...state,
            data: action.payload,
            error: null,
            loading: false,
            success: true
        };
    case 'error':
        return {
            ...state,
            error: action.error,
            loading: false,
            success: false
        };
    default:
        throw new Error();
    }
}

const useRequest = (initial=initialState) => {

    const [state, dispatch] = useReducer(reducer, initial);

    const request = useCallback(() => { dispatch({ type: 'request' }); }, [dispatch])
    const success = useCallback((data=null) => { dispatch({ type: 'success', payload: data }); }, [dispatch])
    const error = useCallback((error=null) => { dispatch({ type: 'error', error: error }); }, [dispatch])

    return {
        success: state.success,
        data: state.data,
        error: state.error,
        loading: state.loading,
        setRequest: request,
        setSuccess: success,
        setError: error
    };
}

export default useRequest;