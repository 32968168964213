import { useState, useEffect, useRef, cloneElement } from 'react';

const Drawer = ({ children, open, openClass }) => {
    const [mounted, setMounted] = useState(false);
    const [visible, setVisible] = useState(false);

    const defaultClass = useRef(children.props.className);

    useEffect(() => {
        if (open) {
            setMounted(true);
        } else {
            setVisible(false);
        }
    }, [open]);

    useEffect(() => {
        setVisible(mounted);
    }, [mounted]);

    const onEnd = () => {
        if (!visible) {
            setMounted(false);
        }
    }

    if (!mounted) { return null; }

    return cloneElement(children, {
        className: defaultClass.current + (visible ? ' ' + openClass : ''),
        onTransitionEnd: onEnd
    })
}

export default Drawer;