import { Switch, Route, Redirect } from 'react-router-dom';

import Config from '../../lib/config/Config';

import ProductList from './ProductList';
import ProductDetail from './ProductDetail';


const Products = ({ match }) => {
    
    const { path } = match;
    const default_category = Config.get('default_category');

    return (
        <Switch>
            <Redirect from={path + '/'} to={path + '/' + default_category} exact />
            <Redirect from={path + '/populair'} to="/populair" exact />
            <Route path={path + '/:category_key'} exact component={ProductList} />
            <Route path={path + '/:category_key/:product_sku'} exact component={ProductDetail} />
        </Switch>
    );
    
}

export default Products;