import { useMemo } from 'react';

import classNames from 'classnames';

import { format, addDays, isToday, isTomorrow, isBefore, isAfter, parse } from 'date-fns';
import { nl } from 'date-fns/locale';

import classes from './Open.module.css';

const useOpen = (regularHours, specialHours=[]) => {

    return useMemo(() => {

        // Overide regular hours with special hours 
        const newRegularHours = Object.keys(regularHours).reduce((hours, day) => {
            if (day !== 'id') { 

                const date = parse(day, 'iiii', new Date());

                const formated_date = format(date, 'yyyy-MM-dd');
                const special = specialHours.find(({ date }) => {
                    return (date === formated_date);
                });

                if (special) {

                    if (special.open) {
                        hours[day] = {
                            open: true,
                            openTime: special.openTime,
                            closeTime: special.closeTime
                        }
                    } else {
                        hours[day] = {
                            open: false,
                        }
                    }

                } else {
                    hours[day] = regularHours[day];
                }
            }
            return hours;

        }, {});

        const toDay = new Date();
        const day = format(toDay, 'EEEE').toLocaleLowerCase();

        let open = false;
        let label = '';

        if (newRegularHours[day] !== undefined) {

            if (newRegularHours[day].open) {
          
                const [ ohh,omm ] = newRegularHours[day].openTime.split(':');
                const openingTime = new Date().setHours(ohh, omm, 0);
           
                const [ chh,cmm ] = newRegularHours[day].closeTime.split(':');
                const closingTime = new Date().setHours(chh, cmm, 0);

                if (!isBefore(toDay, openingTime) && !isAfter(toDay, closingTime)) {
                    label = 'tot ' + chh + ':' + cmm + ' uur';
                    open = true;

                } else {
                    label = 'Open vanaf ' + ohh + ':' + omm + ' uur';
                }

            } else {
                open = false;
            }

        } 

        // If closed find next open day
        if (!open) {
 
            let i=1;
            while(label === '') {
                const nextDay = addDays(toDay, i);

                const dayName = format(nextDay, 'EEEE').toLocaleLowerCase();
          
                if (newRegularHours[dayName] && newRegularHours[dayName].open) {
                    const [ hh,mm ] = newRegularHours[dayName].openTime.split(':');

                    let dayOpen = 'morgen';
                    if (!isToday(nextDay)) {
                    } else if (!isTomorrow(nextDay)) {
                        dayOpen = format(nextDay, 'EEEE', { locale: nl });
                    } 

                    label = 'Open ' + dayOpen + ' om ' + hh + ':' + mm + ' uur.';
                }

                i++;

                if (i===6) { break; }
            }
        }

        return {
            open: open,
            label: label
        }
        
    }, [ regularHours, specialHours ]);

}

const Open = ({ regularHours={}, specialHours=[], className=null }) => {
    const { open, label } = useOpen(regularHours, specialHours);
    
    const openClasses = classNames(classes.root, className);

    return (
        <span className={openClasses}>
            {
                !open
                ?
                <><span className={classes.bold}>Gesloten • </span> { label }</>
                :
                <><span className={classes.green}>Open</span> { label }</>
            }
        </span>
    );
}

export default Open;