import IconWrapper from '../wrapper/IconWrapper';

const UploadIcon = ({ size=24, color=null, direction='right',className=null }) => {

    let directions = {
        right: 'translate(0 9.049) rotate(-90)',
        left: 'translate(0 9.049) rotate(-90)',
        top: 'translate(1 10.462) rotate(-90)',
        bottom: 'translate(13 1.413) rotate(90)'
    }

    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.4668 24.1224C17.863 24.1224 17.373 23.6324 17.373 23.0287V5.4689C17.373 4.86515 17.863 4.37515 18.4668 4.37515C19.0705 4.37515 19.5605 4.86515 19.5605 5.4689V23.0287C19.5605 23.6324 19.0705 24.1224 18.4668 24.1224Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M18.4664 24.1224C18.1762 24.1224 17.8962 24.0072 17.6921 23.8002L13.4396 19.5316C13.0137 19.1029 13.0152 18.4102 13.4425 17.9843C13.8712 17.5585 14.5625 17.5585 14.9883 17.9872L18.4664 21.4799L21.9446 17.9872C22.3704 17.5585 23.0616 17.5585 23.4904 17.9843C23.9177 18.4102 23.9191 19.1029 23.4933 19.5316L19.2408 23.8002C19.0366 24.0072 18.7566 24.1224 18.4664 24.1224Z" fill="white"/>
                <mask id="mask0_2560_200"  maskUnits="userSpaceOnUse" x="3" y="11" width="31" height="21">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.88184 11.2767H33.0482V31.32H3.88184V11.2767Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_2560_200)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.5954 31.32H10.3495C6.78392 31.32 3.88184 28.4194 3.88184 24.8523V17.7284C3.88184 14.17 6.77663 11.2767 10.3364 11.2767H11.7087C12.3125 11.2767 12.8025 11.7667 12.8025 12.3705C12.8025 12.9742 12.3125 13.4642 11.7087 13.4642H10.3364C7.98267 13.4642 6.06934 15.3761 6.06934 17.7284V24.8523C6.06934 27.2134 7.9885 29.1325 10.3495 29.1325H26.5954C28.9462 29.1325 30.861 27.2177 30.861 24.8669V17.7444C30.861 15.3834 28.9404 13.4642 26.5823 13.4642H25.2231C24.6193 13.4642 24.1293 12.9742 24.1293 12.3705C24.1293 11.7667 24.6193 11.2767 25.2231 11.2767H26.5823C30.1479 11.2767 33.0485 14.1788 33.0485 17.7444V24.8669C33.0485 28.4252 30.1523 31.32 26.5954 31.32Z" fill="white"/>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default UploadIcon;