import classNames from 'classnames';

import { LoaderIcon } from '../icons';

import classes from './Loading.module.css';

const Loading = ({ label='Bezig met laden...', className=null, page }) => {

    const loadingClasses = classNames(classes.root, className, {
        [classes.page]: page
    });

    return (
        <div className={loadingClasses}>
            <LoaderIcon size={30}/>
            <span className="text-small">{ label }</span>
        </div>
    );
    
}

export default Loading;