import { useEffect, useState } from 'react';

import Anytyme from '../lib/api/Anytyme';

const useLocation = (key, prefilled_location=null) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState(prefilled_location);

    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);
 
            try {
                const { data=[] } = await Anytyme.getLocation(key);
            
                const location = data.map(({ id, attributes }) => {

                    let image = null;
                    if (attributes?.image?.data) {
                        image = {
                            id: attributes.image.data.id,
                            ...attributes.image.data.attributes
                        }
                    }

                    let extra_image = null;
                    if (attributes?.extra_image?.data) {
                        image = {
                            id: attributes.extra_image.data.id,
                            ...attributes.extra_image.data.attributes
                        }
                    }

                    return {
                        id: id,
                        ...attributes,
                        image: image,
                        extra_image: extra_image
                    }
                });
             
                setLocation(location[0] || null);
            } catch (error) {
                setError(error);
            }
        
            setLoading(false);
        
    
        }

        fetchData();


    }, [ key ]);

    return {
        location: location,
        error: error,
        loading: loading,
    };
}

export default useLocation;