import { useHistory } from "react-router-dom";

import { useSelector } from 'react-redux';

import classNames from 'classnames';

import Grid from '../../../../components/grid/Grid';
import FavouriteButton from '../../../../components/locations/favoritebutton/FavoriteButton';
import Open from '../../../../components/locations/open/Open';
import Services from '../../../../components/locations/services/Services';

import { Button } from '../../../../components/form';
import { MapIcon } from '../../../../components/icons';

import classes from './Location.module.css';

const Location = ({ data }) => {
    const { key, name, distance, regularHours, specialHours, regularDeliveryHours, specialDeliveryHours, delivery, takeaway, orderUrl, storeCode, city } = data;
    const history = useHistory();

    const { favorite_location } = useSelector(state => state.app);

    const rootClasses = classNames(classes.root, ((favorite_location !== null && favorite_location.storeCode === storeCode) ? classes.favorite : null));

    const moreInfo = () => {
        history.push('/vestigingen/'+key, { location: data });
    }

    return (
        <div className={rootClasses}>

            <Grid container spacing={0} className={classes.line}>

                <Grid xs={10}>
                    <h4 className={classes.heading} onClick={moreInfo}>{ name }</h4>
                    <div className={classes.city}>{ city }</div>
                    <Open regularHours={regularHours} specialHours={specialHours} />
                </Grid>

                <Grid xs={2} className={classes.align_right}>
                   <FavouriteButton location={data} />
                </Grid>

            </Grid>

            <Grid container spacing={0} className={classes.line}>
                
                <Grid xs={9} className={classes.attributes}>
                    <Services 
                        delivery={delivery} 
                        takeaway={takeaway}
                        regularHours={regularHours}
                        specialHours={specialHours}
                        regularDeliveryHours={regularDeliveryHours}
                        specialDeliveryHours={specialDeliveryHours}
                    />
                </Grid>

                <Grid xs={3} className={classes.align_right}>
                    {distance !== undefined && <div className={classes.icon_text}><MapIcon size={18} color="#B1B7C0" className={classes.icon} /> { Number(distance/1000).toFixed(0) } km</div>}
                </Grid>

            </Grid>

            <Grid container spacing={0}>

                <Grid sm={5} smOrder={1} xsOrder={2} className={classes.note_container}>
                    { orderUrl !== '' &&  <span className={classes.note}>Bestelling wordt geplaatst bij externe bestelsite.</span> }
                </Grid>

                <Grid sm={7} smOrder={2} xsOrder={1} className={(orderUrl !== '' ? classes.buttons : classes.one_button) }>
                    <Button className={classes.button} onClick={ moreInfo }>Meer info</Button>
                    { orderUrl !== '' && <Button variant="primary" className={classes.button} onClick={() => {window.open(orderUrl, '_blank');}}>Bestellen</Button> }
                </Grid>
           
            </Grid>

            
        </div>
    );
}

export default Location;