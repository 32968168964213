import IconWrapper from '../wrapper/IconWrapper';

const ChevronIcon = ({ size=24, color="#b1b7c0", direction='left', className=null }) => {

    let directions = {
        right: 'translate(3.873 1.061) rotate(90)',
        left: 'translate(1.061 7.306) rotate(-90)',
        top: 'translate(7.306 3.873) rotate(180)',
        bottom: 'translate(1.061 1.061)'
    }

    return (
        <IconWrapper size={size} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="4.933" height="8.367" viewBox="0 0 4.933 8.367">
                <path d="M6.246,0,3.123,3.123,0,0" transform={(directions[direction] ? directions[direction] : directions['left'] )} fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
            </svg>
        </IconWrapper>
    );
}

export default ChevronIcon;