
import Anytyme from '../lib/api/Anytyme';


import useRequest from './useRequest';

const useVacancyApply = () => {
    const { success, error, loading, setRequest, setSuccess, setError } = useRequest();

    const applyVacancy = async (params) => {

        try {
            setRequest();
            const response = await Anytyme.applyVacancy(params);
            setSuccess();
            return true;
        } catch (error) {
            console.log('useVacancyApply', error);
            setError(error);
            return false;
        }

    }

    return {
        success,
        error,
        loading,
        applyVacancy
    };
}

export default useVacancyApply;