import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Favourite from '../components/locations/Favorite';
import CookieConsent from '../components/cookieconsent/CookieConsent';
import GoogleAnalytics from '../components/google_analytics/GoogleAnalytics';

const Layout = ({ children, location }) => {
    return (
        <>
            <Header />

            <Favourite location={location} />

            <main>
                {children}
            </main>

            <CookieConsent />
            <GoogleAnalytics />

            <Footer />
        </>
    );
}

export default Layout;