import { useEffect, useMemo  } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Anytyme from '../lib/api/Anytyme';
import { addNavigation } from '../redux/navigation/actions';


const sortTree = (tree) => {
    tree.sort((a, b) => {
        return a.nav_sequence - b.nav_sequence;
    });

    tree.forEach((node) => {
        if (node.children) {
            sortTree(node.children);
        }
    });

    return tree;
};


const toTree = (list) => {

    const root_id = 0;
    
    let i;
    let node;
    let map = {};
    let roots = [];

    for (i = 0; i < list.length; i++) {
        map[list[i].id] = i;
        list[i].children = [];
    }
    
    for (i = 0; i < list.length; i++) {
        node = list[i];
        if (node.parent_id && node.parent_id !== root_id) {
            
            if (map[node.parent_id]) {
                list[map[node.parent_id]].children.push(node);
            } else {
                list[0].children.push(node);
            }
            
        } else {
            roots.push(node);
        }
    }

    return roots;
};


const useNavigation = () => {
    
    const dispatch = useDispatch();
    const { pages } = useSelector(state => state.navigation);


    useEffect(() => {

        const fetchData = async () => {

            try {
                const { data=[] } = await Anytyme.getPages();

                const pages = data.map(({ id, attributes }) => {
                    return {
                        id: id,
                        ...attributes
                    }
                });
         
                dispatch(addNavigation(pages));
            } catch (error) {
                console.log('Error:', error);
            }
        };

        if (pages === null) {
            fetchData();
        }

    }, [pages, dispatch]);


    const tree = useMemo(() => {
        
        const clone = JSON.parse(JSON.stringify(pages)) || [];
        const tree = toTree(clone);

        return sortTree(tree);
    }, [pages]);

    
    return {
        tree,
        pages
    };
}

export default useNavigation;