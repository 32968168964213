import { Builder } from "@builder.io/react";

import ProductSliderModule from '../../modules/sliders/ProductSlider';
import Grid from '../../components/grid/Grid';

export const ProductSlider = ({ category }) => (
    <Grid container>
        {
            !category ?
            <div style={{padding:16}}>Selecteer een categorie om producten te tonen</div>
            :
            <ProductSliderModule category_key={category} />
        }
    </Grid>
);

Builder.registerComponent(ProductSlider, {
    name: "Product Slider",
    inputs: [{
        name: 'category',
        type: 'string',
        required: true
    }]
});