import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Config from '../../../lib/config/Config';
import Layout from '../../../pages/Layout';

const LayoutRoute = ({ component:Page, ...restProps }) => {
    return (
        <Route {...restProps} render={(routeProps) => {
            
            const { seo, nav } = restProps;
            const { title, description } = seo || {};
            
            const props = { ...routeProps, seo, nav };

            return (
                <>
                    <Helmet
                        defaultTitle={ Config.get('seo').default_title }
                        titleTemplate={ '%s | ' + Config.get('seo').default_title }
                    >
                        <title>{ title || '' }</title>
                        <meta name="description" content={ description || '' } />
                    </Helmet>
                    <Layout {...props}>
                        <Page {...props} />
                    </Layout>
                </>
            );
        }} />
    );
}

export default LayoutRoute;