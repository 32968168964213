import { NavLink } from 'react-router-dom';

import useNavigation from '../../../hooks/useNavigation';

import classes from './Navigation.module.css';

const Navigation = () => {
    const { tree } = useNavigation();

    return (
        <nav className={classes.nav_wrapper}>
            {
                tree && tree.map((page) => {
                    return page.nav_visible ? <NavLink key={page.url_key} to={'/' + page.url_key} className={classes.nav_link} activeClassName={classes.active_link}>{page.nav_name}</NavLink> : null;
                })
            }
        </nav>
    );
}

export default Navigation;