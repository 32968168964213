import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';

import marked from 'marked';

import classNames from 'classnames';

import { Button } from '../../components/form';

import useLocation from '../../hooks/useLocation';
import useMediaQuery from '../../hooks/useMediaQuery';

import Config from '../../lib/config/Config';

import Grid from '../../components/grid/Grid';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import Image from '../../components/image/Image';
import FavouriteButton from '../../components/locations/favoritebutton/FavoriteButton';
import Open from '../../components/locations/open/Open';
import Services from '../../components/locations/services/Services';

import Timeslist from './timeslist/Timeslist';

import classes from './LocationDetails.module.css';

const LocationDetails = ({ location: { state } }) => {
    const { location_key } = useParams();
    const { match } = useMediaQuery('(min-width: 768px)');

    const { location, loading } = useLocation(location_key, (state ? state.location : null));

    const { name, regularHours, specialHours, regularDeliveryHours, specialDeliveryHours, delivery, takeaway, orderUrl, street, number, postalcode, city, primaryPhone, secondaryPhone, email, image, description, extra_image, extra_info_title, extra_info } = location || {};
  
    const infoClasses = classNames(classes.info, (!image ? classes.info_no_image : null ));

    return (
        <>
            <Helmet>
                <title>{ name }</title>
                <meta name="description" content="" />
            </Helmet>

            <Grid container>

            {
                loading && location === null
                ?
                <Card className={classes.loading}>
                    <Loading />
                </Card>
                :
                
                <>
                    {
                        location !== null
                        &&
                        <>
                            <Grid spacing={4} sx={12} className={classes.breadcrumb}>
                            
                                <Breadcrumb
                                    prefix={[{ label: 'Home', path: '/' }]}
                                    paths={[{ label: 'Vestigingen', path: '/vestigingen' }]}
                                    sufix={[{ label: name }]}
                                />

                            </Grid>
                        
            
                            <Grid container>

                                <Grid smSpacing={4} xsSpacing={0} sx={12} >

                                    <Card className={classes.root}>

                                        {
                                            image
                                            ?
                                            <Image lazy responsive src={Config.get('backend').image_url + image.url} alt={name} ratio="location" className={classes.image} />
                                            :
                                            null
                                        }
                                        
                                        <Grid container className={infoClasses}>

                                            <Grid container spacing={0}>

                                                <Grid xs={10}>
                                                    <div className={classes.favourite_phrase}>Favoriete vestiging</div>
                                                    <h1 className={classes.heading}>{ name }</h1>
                                                </Grid>

                                                <Grid xs={2} className={classes.align_right}>
                                                    <FavouriteButton location={location} size={(match ? 42 : 25)} />
                                                </Grid>

                                            </Grid>

                                            <Grid container spacing={0} className={classes.line}>
                                                
                                                <Grid sm={5} xs={12} >
                                                    <Open regularHours={regularHours} specialHours={specialHours} />
                                                </Grid>

                                                <Grid sm={7} xs={12} className={classes.services}>
                                                    <Services 
                                                        delivery={delivery} 
                                                        takeaway={takeaway}
                                                        regularHours={regularHours}
                                                        specialHours={specialHours}
                                                        regularDeliveryHours={regularDeliveryHours}
                                                        specialDeliveryHours={specialDeliveryHours}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid container nesting spacing={0}>
                                                <Grid container nesting spacing={0} smOrder={1} xsOrder={2} className={classes.line}>
                                                    
                                                    <Grid sm={5} xs={12} className={classes.adres} >
                                                        <h4 className={classes.heading_times}>Adres en contact</h4>
                                                        <span>
                                                        { street } { number }<br/>
                                                        { postalcode } { city }<br/>
                                                        <br/>
                                                        <a href={"tell:"+primaryPhone} className={classes.link}>{ primaryPhone }</a><br />
                                                        { (secondaryPhone !== '' && secondaryPhone !== primaryPhone) && <><a href={"tell:"+secondaryPhone} className={classes.link}>{ secondaryPhone }</a><br/></> }
                                                        { email } 
                                                        </span>
                                                    </Grid> 

                                                    <Grid sm={4} xs={6}>
                                                        <h4 className={classes.heading_times}>Openingstijden</h4>
                                                        <Timeslist hours={ regularHours } specialHours={specialHours} />
                                                    </Grid>
                                                    
                                                    {
                                                        delivery
                                                        &&
                                                        <Grid sm={3} xs={6}>
                                                            <h4 className={classes.heading_times}>Bezorgtijden</h4>
                                                            <Timeslist hours={ regularDeliveryHours } specialHours={specialDeliveryHours} />
                                                        </Grid>
                                                    }
                                                    
                                                </Grid>
                                                { 
                                                    orderUrl !== ''
                                                    &&
                                                    <Grid container nesting spacing={0} smOrder={2} xsOrder={1}>

                                                        <Grid sm={8} smOrder={1} xsOrder={2} className={classes.center}>
                                                            <span className={classes.note}>Bestelling wordt geplaatst bij externe bestelsite.</span>
                                                        </Grid>

                                                        <Grid sm={4} smOrder={2} xsOrder={1} className={classes.buttons}>
                                                            <Button variant="primary" className={classes.button} onClick={() => {window.open(orderUrl, '_blank');}}>Bestellen</Button>
                                                        </Grid>

                                                    </Grid>
                                                }
                                            </Grid>

                                            {
                                                description
                                                &&
                                                <Grid container nesting spacing={0} className={classes.line}>
                                                    <Grid xs={12}>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: marked(description, { headerIds: false })
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }

                                            {
                                                (extra_info_title && extra_info)
                                                &&
                                                <Card className={classes.extra_info}>

                                                    <Grid container spacing={(match ? 3 : 0)}>

                                                        {
                                                            extra_image
                                                            &&
                                                            <Grid sm={6} xs={12} className={classes.extra_info_image}>
                                                            {
                                                                extra_image
                                                                ?
                                                                <img src={Config.get('backend').image_url + extra_image.url} alt={name} className={classes.extra_image} />
                                                                :
                                                                null
                                                            }
                                                            </Grid>
                                                        }

                                                        <Grid sm={(extra_image ? 6 : 12 )} xs={12} className={classes.extra_info_text}>
                                                            <h3>{ extra_info_title }</h3>
                                                            <p>{ extra_info }</p>
                                                        </Grid>

                                                    </Grid>

                                                </Card>
                                            }

                                        </Grid>
                                        

                        

                                    </Card>
                                </Grid>
                            </Grid>
                        
                    </>
                    }
                </>

            }

            </Grid>

        </>
    );
    
}

export default LocationDetails;