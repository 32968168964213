import { Builder } from "@builder.io/react";
import { Link, useHistory } from 'react-router-dom';

import { Button } from "../../components/form";

import Grid from '../../components/grid/Grid';

import LocationSearchComponent from '../../components/locations/search/LocationSearch';

import classes from './HomeBanner.module.css';

const HomeBanner = (props) => {
    const history = useHistory();
    const { title, text, buttonText, backgroundimage='/images/homde-banner-bg.png' } = props;

    return (
        <Grid spacing={4} container className={classes.root} >
            <div className={classes.background} style={{ backgroundImage:'url('+backgroundimage+')' }}>
            <div className={classes.intro}>
                <h1>{ title }</h1>
                <p>{ text }</p>

                <Link to={'/assortiment/burgers'} className={classes.button}>{ buttonText }</Link>

            </div>
            <div className={classes.locationSearch}>
                <LocationSearchComponent onSearch={(value) => history.push('/vestigingen?locatie=' + value)} />
            </div>
            </div>
        </Grid>
    )
}

export default HomeBanner;

Builder.registerComponent(HomeBanner, {
    name: "Home Banner",
    inputs: [
        {
            name: 'title',
            type: 'string',
            defaultValue: 'Your title here',
        },
        {
            name: 'text',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'buttonText',
            type: 'string',
            defaultValue: 'Your button text here',
        },
        {
            name: 'backgroundimage',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
    ],
});
