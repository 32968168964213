import Slider from '../../components/slider/Slider';
import ProductTile from '../../components/product/tile/ProductTile';
import useCategory from '../../hooks/useCategory';

const ProductSlider = ({ category_key }) => {

    const { category } = useCategory(category_key);

    const renderItem = (item) => {
        return <ProductTile product={item} category={category_key} />;
    }

    if (category === null) { return null; }

    return (
        <Slider
            items={category.products}
            renderItem={renderItem}
        />
    );
}

export default ProductSlider;