import { useRef } from 'react';

const Form = ({ children, onSubmit, ...props }) => {

    const form = useRef(null);

    const resetForm = () => {
        form.current.classList.remove('validated');
    }

    const validateForm = (event) => {
        
        const valid = form.current.checkValidity();

        form.current.classList.add('validated');

        if (onSubmit) {
            onSubmit(valid, resetForm);
        }

        event.preventDefault();
    }

    return (
        <form {...props} ref={form} onSubmit={validateForm} noValidate>
            { children }
        </form>
    );
}

export default Form;