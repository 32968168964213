import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ChevronIcon } from '../../components/icons';

import useCategory from '../../hooks/useCategory';
import useProduct from '../../hooks/useProduct';
import useMediaQuery from '../../hooks/useMediaQuery';

import Config from '../../lib/config/Config';
import Grid from '../../components/grid/Grid';
import Card from '../../components/card/Card';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import Image from '../../components/image/Image';
import ProductList from '../../components/product/ProductList';
import ProductTile from '../../components/product/tile/ProductTile';
import OrderDirect from '../../components/order_direct/OrderDirect';

import classes from './ProductDetail.module.css';

const ProductDetail = ({ location: { state } }) => {
    const { product_sku, category_key } = useParams();
    const { match } = useMediaQuery('(min-width: 768px)');

    const { product, loading } = useProduct(product_sku, (state ? state.location : null));
    const { category } = useCategory(category_key);

    let paths = [];
    let sufix = [];
    let canonical_url = '';

    if (product && category) {
        
        paths = [{ label: category.name, path: '/assortiment/'+category.key }];
        sufix = [{ label: product.name }];
        
        canonical_url = 'https://www.anytyme.nl/assortiment/'+product.categories[0].key+'/' + product.sku;
    }

    const renderItem = (product) => {
        return <ProductTile product={product} category={category_key} />;
    }

    const getProductImage = (image) => {
        return image ? Config.get('backend').image_url + image.url : '/images/placeholder/placeholder.jpg';
    };

    if (loading && product === null) { return <Loading page />; }

    const price = (product && product.price !== 0 ? Number(product.price).toFixed(2).replace('.', ',') : null)

    return (
        <>
            <Helmet>
                <title>{ product && product.name }</title>
                <meta name="description" content={ product && product.description } />
                <link rel="canonical" href={canonical_url} />
            </Helmet>
            
            <Grid container>

            {
                product !== null
                &&
                <>
                    <Grid spacing={4} sx={12} className={classes.breadcrumb}>
                        {
                            match
                            ?
                            <Breadcrumb
                                prefix={[{ label: 'Home', path: '/' }]}
                                paths={paths}
                                sufix={sufix}
                            />
                            :
                            <>
                                {
                                    category
                                    &&
                                    <Link to={'/assortiment/'+category.key} className={classes.back} > 
                                        <ChevronIcon size={10} color='#11BBFF' direction='right' className={classes.back_icon} /> { category.name }
                                    </Link>
                                }
                            </>
                        }
                        

                    </Grid>

        
                        <Grid xsSpacing={5} smSpacing={4} container>
                            <Grid>
                                
                                <Card className={classes.card}>
                                    <Grid xsSpacing={0} smSpacing={3} container className={classes.product_container}>
                                        
                                        <Grid xs={12} sm={6} >
                                            <div className={classes.image_container}>
                                                <Image lazy responsive src={ getProductImage(product.image) } alt={product.name} width={450} height={409} ratio="product" className={classes.image} />
                                                {
                                                        product.popular
                                                        &&
                                                        <>
                                                            <div className={classes.label}>Populair</div>
                                                            {
                                                                price !== null
                                                                &&
                                                                <div className={classes.price}>{ price }</div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                        </Grid>

                                        <Grid xs={12} sm={6} className={classes.description_container}>
                                            <h1>{ product.name }</h1>
                                            <p>{ product.description }</p>
                                        </Grid>            
                                
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>

                        {
                            (category && category.products)
                            &&
                            <>

                                <Grid xsSpacing={3} smSpacing={0} container>

                                    <Grid xsSpacing={3} smSpacing={4} >
                                        <Grid className={classes.products_heading}>
                                            <h2>Meer { category.name.toLowerCase() }</h2>
                                        </Grid>
                                    </Grid>

                                    <Grid>
                                        <ProductList
                                            products={category.products.filter((prod) => {
                                                return prod.id !== product.id;
                                            })}
                                            renderItem={renderItem}
                                        />
                                    </Grid>
                                </Grid>
              
                            </>
                        }
            
                </>
            }
            </Grid>

            <OrderDirect />
        </>
    );   
}

export default ProductDetail;