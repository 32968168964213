import classNames from 'classnames';

import { LoaderIcon } from '../../icons';

import classes from './Button.module.css';

const Button = ({ children, type="submit", variant="normal", loading=false, onClick=()=>{}, className='' }) => {

    const buttonClasses = classNames(classes.root, classes[variant], className);

    return (
        <button 
            className={buttonClasses} 
            type={type}
            onClick={onClick}
            disabled={loading}
        >
            { 
                loading
                ?
                    <div className={classes.loading}><LoaderIcon color={'#ffffff'} /></div>
                :
                    children 
            }
        </button>
    );
    
}

export default Button;