import { useState, useCallback, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import classNames from 'classnames';

import Grid from '../../components/grid/Grid';
import Card from '../../components/card/Card';
import Map from '../../components/googlemaps/Map';
import Marker from '../../components/googlemaps/Marker';

import Config from '../../lib/config/Config';

import useLocations from '../../hooks/useLocations';
import useMediaQuery from '../../hooks/useMediaQuery';

import List from './list/List';

import classes from './LocationsList.module.css';

const LocationsList = ({ location: { state } }) => {
    
    const [ hidemap, setHideMap ] = useState(false);
    const { match } = useMediaQuery('(min-width: 1200px)');
    const { locations, loading, search, searchLatLon, center } = useLocations();
    const history = useHistory();

    useEffect(() => {
        // History state
        const coordinates = (state ? state.coordinates : null);

        if (coordinates) {
            searchLatLon(coordinates.latitude, coordinates.longitude);
        }
        
    }, [state]);

    const onShowContent = useCallback((value) => {
        setHideMap(value)
    }, []);

    let mapZoom = (center !== null ? 8 : 7);
    let mapCenter = (center !== null ? center : { lat: 52.132633, lng: 5.291266 });

    if (match) {
        if (center === null) {
            mapCenter.lng -= 1.40; // move 1.45 to the left to show all markers
        }

        mapZoom = (center !== null ? 9 : 8);
    }

    return (
     
        <div className={classes.root}>

            <div className={ classNames(classes.map_container, (hidemap && classes.hide))}>
                <Map 
                    zoom={mapZoom}
                    center={mapCenter} 
                    className={classes.map} 
                    ApiKey={Config.get('google').maps_api_key}
                >
                    {
                        locations.filter((location) => {
                            return location.coordinates !== null;
                        }).map((location) => {
                            const { key, storeCode, name, coordinates: { latitude, longitude }, street, number, postalcode, city } = location;
              
                            return (
                                <Marker 
                                    key={storeCode}
                                    title={name}
                                    position={{
                                        lng: Number(longitude),
                                        lat: Number(latitude)
                                    }}
                                    street={street}
                                    number={number}
                                    postalcode={postalcode}
                                    city={city}
                                    onButtonClick={() => {
                                        history.push('/vestigingen/'+key, { location: location })
                                    }}
                                />
                            );
                        })
                    }
                </Map>
            </div>
        
            <div className={classes.list_container}>
            
                <Grid xsSpacing={5} smSpacing={4} container>

                    <Grid md={12}>

                        <List 
                            onShowContent={onShowContent}
                            onSearch={(value) => { search(value) }}
                            onSearchLatLon={searchLatLon}
                            locations={locations}
                            loading={loading}
                        />
                        
                    </Grid>

                </Grid>
            </div>
            
         
        </div>

    )
}

export default LocationsList;