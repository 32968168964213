import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import Input from '../../form/input/Input';
import MyLocation from '../mylocation/MyLocation';

import classes from './LocationSearch.module.css';

const LocationSearch = ({ onSearch = null, className = '' }) => {
    const history = useHistory();
    const [value, setValue] = useState('');

    const formClasses = classNames(classes.root, className);

    const onSubmit = (event) => {
        event.preventDefault();

        if (onSearch !== null && value !== '') { onSearch(value); }
    }

    const onInputChange = ({ target: { value } }) => {
        setValue(value);
    }

    const onLocationChange = ({ latitude, longitude }) => {
        
        if (latitude && longitude) {
            history.push('/vestigingen', {
                coordinates: {
                    latitude,
                    longitude
                }
            })
        }
    }

    return (
        <div className={classes.card}>
            <form className={formClasses} onSubmit={onSubmit}>

                <h2 className={classes.heading} >Zoek jouw vestiging</h2>

                <Input name="location" placeholder="postcode of plaats" onChange={onInputChange} value={value} />

                <button
                    className={classes.button}
                    type="submit"
                >
                    Zoek vestiging
                </button>

                <MyLocation 
                    onLocation={onLocationChange}
                    className={classes.mylocation}
                />

            </form>
        </div>
    );

}

export default LocationSearch;