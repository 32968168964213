import IconWrapper from '../wrapper/IconWrapper';

const CheckIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8" fill="#24d880"/>
                <path d="M0,2.292,2.293,4.585,6.878,0" transform="translate(4.257 6.068)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
            </svg>
        </IconWrapper>
    );
}

export default CheckIcon;