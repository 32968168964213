import { Link } from 'react-router-dom';
import { Builder, Image } from "@builder.io/react";

import CardComponent from '../../components/card/Card';
import Grid from '../../components/grid/Grid';

import classes from './ThreeColumnBanner.module.css';
import { ChevronIcon } from '../../components/icons';

export const ThreeColumnBanner = props => {
    const { Padding, sectionTitle1, text1, url1, image1, sectionTitle2, text2, url2, image2, sectionTitle3, text3, url3, image3 } = props;

    return (
        <Grid xsSpacing={3} smSpacing={4} container>
            {[
                { img: image1, sectionTitle: sectionTitle1, text: text1, url: url1 },
                { img: image2, sectionTitle: sectionTitle2, text: text2, url: url2 },
                { img: image3, sectionTitle: sectionTitle3, text: text3, url: url3 },
            ].map((col, index) => (
                <Grid key={index} sm={4} className={classes.banner_container}>
                    <CardComponent className={classes.card} style={{ padding: Padding + 'px' }}>
                        <Grid container>
                            <Grid xs={4} sm={12}>
                                <div className={classes.imageContainer + ' ' + classes['padding_' + Padding]}>
                                    <Image lazy aspectRatio={1} image={col.img} />
                                </div>
                            </Grid>
                            <Grid xs={8} sm={12} className={classes.text_container}>
                                <div className={classes.title}>
                                    {col.sectionTitle}
                                </div>
                                <div className={classes.text}>
                                    {
                                        col.text
                                    }
                                </div>
                            </Grid>
                            {col.url && <a href={col.url} className={classes.banner_link}><ChevronIcon size={14} className={classes.chevron} color="#FDC619" /></a>}
                        </Grid>
                    </CardComponent>
                </Grid>
            ))}
        </Grid>
    );
}


Builder.registerComponent(ThreeColumnBanner, {
    name: "Three Column Banner",
    inputs: [
        {
            type: 'string',
            name: 'Padding',
            required: true,
            enum: [
                {
                    label: 'Padding 10',
                    value: '10',
                },
                {
                    label: 'Padding 20',
                    value: '20',
                },
                {
                    label: 'Padding 30',
                    value: '30',
                }
            ],
            defaultValue: '10',
        }, {
            name: 'sectionTitle1',
            type: 'string',
            defaultValue: 'Your Title Here',
        },
        {
            name: 'text1',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'url1',
            type: 'string',
        },
        {
            name: 'image1',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }, {
            name: 'sectionTitle2',
            type: 'string',
            defaultValue: 'Your Title Here',
        },
        {
            name: 'text2',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'url2',
            type: 'string',
        },
        {
            name: 'image2',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        }, {
            name: 'sectionTitle3',
            type: 'string',
            defaultValue: 'Your Title Here',
        },
        {
            name: 'text3',
            type: 'string',
            defaultValue: 'Your text here',
        },
        {
            name: 'url3',
            type: 'string',
        },
        {
            name: 'image3',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
            defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
    ],
});
