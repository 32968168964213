import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import useCategory from '../../hooks/useCategory';
import useCategories from '../../hooks/useCategories';

import List from '../../components/product/ProductList';
import ProductTile from '../../components/product/tile/ProductTile';
import Grid from '../../components/grid/Grid';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import CategoryList from '../../components/category/CategoryList';
import Loading from '../../components/loading/Loading';
import OrderDirect from '../../components/order_direct/OrderDirect';

import classes from './ProductList.module.css';

const ProductList = () => {

    const { category_key } = useParams();
    const { categories, loading } = useCategories();
    const { category } = useCategory(category_key);

    const renderItem = (product) => {
        return <ProductTile product={product} category={category_key} />;
    }

    if (loading) { return <Loading page />; }
    if (category === null) { return null; }

    return (
        <>
            <Helmet>
                <title>{category.name}</title>
                <meta name="description" content={category.description} />
            </Helmet>

            <Grid xsSpacing={5} smSpacing={4} container>
                <Grid>
                    <Breadcrumb
                        prefix={[{ label: 'Home', path: '/' }]}
                        paths={[{ label: 'Assortiment', path: '/assortiment' }]}
                        sufix={[{ label: category.name }]}
                    />
                </Grid>
            </Grid>


            <Grid container>

                <Grid xsSpacing={3} smSpacing={0} container>
                    <Grid className={classes.category_list_container}>
                        <CategoryList categories={categories} />
                    </Grid>
                </Grid>

                <Grid xsSpacing={5} smSpacing={4} container className={classes.intro}>
                    <Grid xs={12} sm={9} className={classes.intro_grid} >
                        <h1>{category.name}</h1>
                        <p>{category.description}</p>
                    </Grid>
                </Grid>

                <Grid xsSpacing={3} smSpacing={0} container>
                    <Grid>
                        <List
                            products={category.products}
                            renderItem={renderItem}
                        />
                    </Grid>
                </Grid>
            </Grid>

        
            <OrderDirect />
        </>
    );
}

export default ProductList;