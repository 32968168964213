import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import classNames from 'classnames';

import classes from './Map.module.css';

const Map = ({ children, className='', mapOptions={}, ApiKey="", zoom=10, center={ lat: 52.370216, lng: 4.895168 } }) => {
    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: ApiKey})

    const mapClasses = classNames(classes.root, className);

    const onLoad = (map) => {
        //console.log(map);
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return (
        <div className={mapClasses}>
            {
                isLoaded
                ?
                    <GoogleMap
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        options={ {
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            clickableIcons: false,
                            ...mapOptions
                        }}
                        onLoad={onLoad}
                        zoom={zoom}
                        center={center}
                    >
                        {
                            children
                        }
                    </GoogleMap>
                :
                    <div>Loading...</div>
            }
        </div>
    )
};
  
export default Map;