import { useMemo } from 'react';

import { useSelector } from 'react-redux';


const useCategory = (category_key) => {
    
    const { data, loading, error } = useSelector(state => state.categories);
    
    const category = useMemo(() => {
        
        let cached;
        
        if (data) {
            cached = data.find((category) => category.key === category_key);
        }

        return cached || null;
    }, [category_key, data]);
    
    return {
        category,
        error: error,
        loading: loading
    };
}

export default useCategory;