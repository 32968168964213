import Navigation from './navigation/Navigation';
import StoreLogo from '../logo/StoreLogo';
import Grid from '../grid/Grid';
import Menu from './menu/Menu';

import classes from './Header.module.css';

const Header = () => {
    return (
        <header className={classes.page_header}>
            <Grid container className={classes.container}>
                <StoreLogo classname="header_logo" />
                <Navigation />
                <Menu />
            </Grid>
        </header>
    );
}

export default Header;