import IconWrapper from '../wrapper/IconWrapper';

const MenuIcon = ({ size=24, color=null, className=null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19">
                <g transform="translate(-318.5 -23.5)">
                    <line x1="20" transform="translate(320 25)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
                    <line x1="20" transform="translate(320 33)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
                    <line x1="20" transform="translate(320 41)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
                </g>
            </svg>
        </IconWrapper>
    );
}

export default MenuIcon;