import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';


const initialState = {};

export default createReducer(initialState, {
    [actions.setCache]: (state, action) => {
        const { payload } = action;
        
        state[payload.key] = payload.value;
    },
    [actions.deleteCache]: (state, action) => {
        const { payload } = action;
        
        delete state[payload];
    },
    [actions.clearCache]: (state, action) => {
        return initialState;
    }
});
