import { useState, useEffect } from 'react';

import { Builder, builder, BuilderComponent } from '@builder.io/react/lite';

import useCache from '..//hooks/useCache';

import './index';
import './builder.css';

const BuilderContent = ({ model, url = null, renderLoading = null, renderNotFound = null }) => {
    const [notFound, setNotFound] = useState(false);
    const cache = useCache();

    const builderProps = {};

    if (url !== null) {
        builderProps.url = url;
    }

    if (cache.get(url) !== null) {
        builderProps.content = cache.get(url);
    }

    useEffect(() => {

        const fetchContent = async () => {
            try {
                const content = await builder.get(model, { url: url }).promise();

                setNotFound(!content);

                if (!Builder.isPreviewing && !Builder.isEditing && content) {
                    cache.set(url, content);
                }

            } catch (error) {
                console.log(error);
            }
        }

        fetchContent();

    }, []);

    return (
        <>
            {
                notFound
                    ?
                    renderNotFound && renderNotFound()
                    :
                    <BuilderComponent model={model} {...builderProps} >
                        {
                            renderLoading && renderLoading()
                        }
                    </BuilderComponent>
            }
        </>
    );
}

export default BuilderContent;