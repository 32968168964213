import { Link } from 'react-router-dom';

import Logo from '../../images/logo.webp';

import classes from './Storelogo.module.css';

const StoreLogo = ({ classname }) => {
    return (
        <Link to="/">
            <img src={Logo} alt="AnyTyme" width="208" height="48" className={classes[classname]} />
        </Link>
    );
}

export default StoreLogo;