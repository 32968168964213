import React from "react";

import classNames from 'classnames';

import classes from './IconWrapper.module.css';

const IconWrapper = ({ children, size=24, color=null, className=null }) => {

    const wrapperClasses = classNames(classes.root, className);

    let svgStyle = { }

    if (color !== null) { svgStyle.fill = color; }

    return (
        <span className={wrapperClasses} style={{ width: size + 'px', height: size + 'px' }}>
            { React.cloneElement(children, { style: svgStyle }) }
        </span>
    );
}

export default IconWrapper;